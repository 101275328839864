import { useState } from "react";
import AddNewCustomerModal from "../../components/AddNewCustomerModal";
import SideBar from "../../components/Sidebar";
import { startOfDay } from "date-fns";
import Navbar from "../../components/Navbar";
import Navbar2 from "../../components/Navbar2";
import LogoutModal from "../../components/LogoutModal";
import SuccessModal from "../../components/SuccessModal";
import ProductsModal from "../../components/ProductsModal";
import SalesHistory from "../../components/SalesHistory";
import DisputeModal from "../../components/DisputeModal";
import { Calender } from "../../components/elements/Calender";

export const MarketerHistory = () => {
  const [tabNavigation, setTabNavigation] = useState(false);
  const [customerAcctNo, setCustomerAcctNo] = useState("");
  const [products, setProducts] = useState({});
  const [customerId, setCustomerId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
  const [modals, setModals] = useState({
    logout: false,
    dispute: false,
    addCustomer: false,
    success: false,
    products: false,
    calendar: false
  });

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return (
    <div className="flex">
      <SideBar
        tabNavigation={tabNavigation}
        toggleTabNavigation={toggleTabNavigation}
        openModal={() => openModal("logout")}
      />
      <div className="container ml-0 lg:ml-[20%] ">
        <Navbar
          toggleTabNavigation={toggleTabNavigation}
          tabNavigation={tabNavigation}
        />
        <Navbar2 tab="History" openModal={() => openModal("dispute")} />
        <div className="bg-[#F6F4F8] py-1 px-4 lg:px-6 2xl:px-8 pb-8">
          {modals.logout && (
            <LogoutModal closeModal={() => closeModal("logout")} />
          )}
           {modals.calendar && (
                 <Calender
                   selectedDate={selectedDate}
                   setSelectedDate={setSelectedDate}
                   closeModal={() => closeModal("calendar")}
                   customerId={customerId}
                 />
               )}
           {modals.dispute && (
            <DisputeModal closeModal={() => closeModal("dispute")} />
          )}
          {modals.addCustomer && (
            <AddNewCustomerModal
              closeModal={() => closeModal("addCustomer")}
              openModal={() => openModal("success")}
              setCustomerAcctNo={setCustomerAcctNo}
            />
          )}
          {modals.success && (
            <SuccessModal
              closeModal={() => closeModal("success")}
              customerAcctNo={customerAcctNo}
            />
          )}
          {modals.products && (
            <ProductsModal closeModal={() => closeModal("products")} productsObj={products}/>
          )}
          <section className="flex items-start justify-between w-full mt-6">
            <SalesHistory
              openModal={() => openModal("addCustomer")}
              openSuccessModal={() => openModal("success")}
              openProductModal={() => openModal("products")}
              openCalendarModal={() => openModal("calendar")}
              setProductsObj={setProducts}
              setCustomerId={setCustomerId}
            />
          </section>
        </div>
      </div>
      x
    </div>
  );
};
