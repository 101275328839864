import axios from "axios";
import toast from "react-hot-toast";
import { useState, ChangeEvent, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { IoIosCamera } from "react-icons/io";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

interface Errors {
  password?: string;
  // username?: string;
  phoneNumber?: string;
  address?: string;
  fullName?: string;
}

export const Settings = () => {
  const [inputValue, setInputValue] = useState({
    password: "",
    // username: "",
    phoneNumber: "",
    address: "",
    fullName: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordLoading, setIsPasswordLoading] = useState(false)
  const [fileInput, setFileInput] = useState<File | null>(null);
  const { token, uid, role } = useAppSelector((store: RootState) => store.auth);

  const [file, setFile] = useState("assets/picture.jpg");
  useEffect(() => {
    getMarketerHandler();
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileInput(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters and contain both letters and numbers";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateForm2 = () => {
    let isValid = true;
    const newErrors: Errors = {};

    if (inputValue.fullName.trim() === "") {
      newErrors.fullName = "Please enter full name";
      isValid = false;
    }

    if (inputValue.address.trim() === "") {
      newErrors.address = "Please enter your full address";
      isValid = false;
    }
    if (inputValue.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Please enter your phone number";
      isValid = false;
    }
    // if (inputValue.username.trim() === "") {
    //   newErrors.username = "Please enter username";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  let endpoint;

  const getMarketerHandler = async () => {
    if (role === "marketer") {
      endpoint =  `${process.env.REACT_APP_API_BASE_URL}/getMarketer/${uid}`
    } else{
      endpoint =  `${process.env.REACT_APP_API_BASE_URL}/getAdmin`
    }
    try {
      const response = await axios.get(
       endpoint,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setFile(response.data.image)
      setInputValue({
        password: "",
        // username: response.data.username || "",
        phoneNumber: response.data.phoneNumber || "",
        address: response.data.address || "",
        fullName: response.data.fullName || "",
      });
    } catch (error: any) {

    }
  };

  const handleProfileUpdate = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (validateForm2()) {
      const formdata = new FormData();
      // formdata.append("username", inputValue.username);
      formdata.append("phoneNumber", inputValue.phoneNumber);
      formdata.append("fullName", inputValue.fullName);
      formdata.append("address", inputValue.address);
      if (fileInput) {
        formdata.append("image", fileInput);
      }
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/updateMarketer`,
          formdata,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        toast.success(response.data.message);
        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        toast.error("Unable to update profile, Please try again later");
      }
    }
  };

  const handlePasswordChange = async (e: any) => {
    setIsPasswordLoading(true)
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        newPassword: inputValue.password,
      };

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/updateMarkerPassword`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        toast.success(response.data.message);
        setIsPasswordLoading(false)
      } catch (error: any) {
        setIsPasswordLoading(false)
        toast.error("Unable to change password, Please try again later");
      }
    }
  };

  return (
    <div className="bg-[#F6F4F8] px-4 lg:px-8 pb-8 md:h-full h-fit flex flex-col py-5">
      <form className="bg-white p-6 rounded" onSubmit={handleProfileUpdate}>
        <h2 className="font-semibold text-xl">Profile</h2>

        <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
          <div>
            <h3 className="font-semibold text-sm">Add picture</h3>
            <p className="text-xs">
             
            </p>
          </div>
          <div className="relative w-24 h-24 border bg-blue-200 border-blue-200 rounded-full flex items-center">
            <img
              className="object-cover rounded-full w-full h-full"
              src={file}
              alt=""
            />
            <label className="absolute" htmlFor="picture">
              <span className="text-xs text-white text-center flex items-center justify-center flex-col">
                <IoIosCamera />
                Click to change photo
              </span>
              <input
                className="w-5"
                type="file"
                accept=".jpg,.png,.jpeg"
                id="picture"
                onChange={handleFileChange}
                hidden
              />
            </label>
          </div>
        </div>
        {/* <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
          <div>
            <h3 className="font-semibold text-sm">Username</h3>
            <p className="text-xs">
              Lorem ipsum dolor sit amet consectetur. Maecenas sed neque eget
            </p>
          </div>
          <div className="">
            <label htmlFor="staff-id">
              <input
                name="username"
                value={inputValue.username}
                onChange={handleInputChange}
                className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                type="text"
                id="staff-id"
                placeholder="Enter username"
              />
              {errors.username && (
                <p className="text-red-500 text-xs">{errors.username}</p>
              )}
            </label>
          </div>
        </div> */}
        <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
          <div>
            <h3 className="font-semibold text-sm">Full Name</h3>
            <p className="text-xs">
             
            </p>
          </div>
          <div className="">
            <label htmlFor="full-name">
              <input
                name="fullName"
                value={inputValue.fullName}
                onChange={handleInputChange}
                className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                type="text"
                id="full-name"
                placeholder="Enter full name"
              />
              {errors.fullName && (
                <p className="text-red-500 text-xs">{errors.fullName}</p>
              )}
            </label>
          </div>
        </div>
        {/* <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
            <div>
              <h3 className="font-semibold text-sm">Email</h3>
              <p className="text-xs">
                Lorem ipsum dolor sit amet consectetur. Maecenas sed neque eget
              </p>
            </div>
            <div className="">
              <label htmlFor="email">
                <input
                  className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter email address"
                />
              </label>
            </div>
          </div> */}
        <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
          <div>
            <h3 className="font-semibold text-sm">Phone number</h3>
            <p className="text-xs">
            
            </p>
          </div>
          <div className="">
            <label htmlFor="phone-no">
              <input
                name="phoneNumber"
                value={inputValue.phoneNumber}
                onChange={handleInputChange}
                className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                type="tel"
                id="phone-no"
                placeholder="Enter phone number"
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-xs">{errors.phoneNumber}</p>
              )}
            </label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center border-b">
          <div>
            <h3 className="font-semibold text-sm">House address</h3>
            <p className="text-xs">
             
            </p>
          </div>
          <div className="">
            <label htmlFor="house-address">
              <input
                name="address"
                value={inputValue.address}
                onChange={handleInputChange}
                className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                type="text"
                id="house-address"
                placeholder="Enter house address"
              />
              {errors.address && (
                <p className="text-red-500 text-xs">{errors.address}</p>
              )}
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="bg-violet-950 rounded text-sm text-white py-4 px-7 my-3 w-full"
        >
       {isLoading ? <LoadingSpinner /> : "Update"}  
        </button>
      </form>

      <section className="bg-white px-4 lg:px-8 pb-8 md:h-full flex flex-col py-5 mt-5">
        <h3 className="font-semibold text-xl">Update password</h3>
        <form onSubmit={handlePasswordChange}>
          <div className="flex flex-col md:flex-row py-4 gap-4 justify-between md:items-center">
            <div>
              <h3 className="font-semibold text-sm">Change Password</h3>
              <p className="text-xs">
               
              </p>
            </div>
            <label htmlFor="password">
              <input
                name="password"
                value={inputValue.password}
                onChange={handleInputChange}
                className="bg-secondary100 border text-gray-500 w-full md:w-80 p-2 rounded text-xs"
                type="password"
                id="password"
                placeholder="Enter password"
              />
              {errors.password && (
                <p className="text-red-500 text-xs">{errors.password}</p>
              )}
            </label>
          </div>
          <button
            onClick={handlePasswordChange}
            type="button"
            className="bg-violet-950 rounded text-sm text-white py-4 px-7 my-3 w-full"
          >
        {isPasswordLoading ? <LoadingSpinner /> : "Update Password"}
          </button>
        </form>
      </section>
    </div>
  );
};
