import { useState, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { GoXCircle } from "react-icons/go";
import { AddProductModalProps } from "../../types/types";

interface Errors {
  name?: string;
  brand?: string;
  description?: string;
  stock?: string;
  outrightPrice?: string;
  flexiblePrice?: string;
  image?: string;
}

export const AddProductModal = ({
  setShowSuccess,
  closeModal,
}: AddProductModalProps) => {
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    brand: "",
    description: "",
    stock: "",
    outrightPrice: "",
    flexiblePrice: "",
    image: null as File | null,
  });

  const { token } = useAppSelector((store: RootState) => store.auth);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    setErrors((prev) => ({ ...prev, [name]: undefined }));

    if (files && files[0]) {
      setInputValue((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setInputValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors: Errors = {};
    let isValid = true;

    if (!inputValue.name.trim()) {
      newErrors.name = "Please enter the name of the product";
      isValid = false;
    }
    if (!inputValue.brand.trim()) {
      newErrors.brand = "Please enter the brand of the product";
      isValid = false;
    }
    if (!inputValue.description.trim()) {
      newErrors.description = "Please enter a description";
      isValid = false;
    }
    if (!inputValue.stock.trim()) {
      newErrors.stock = "Please enter the quantity";
      isValid = false;
    }
    if (!inputValue.outrightPrice.trim()) {
      newErrors.outrightPrice = "Please enter outright price";
      isValid = false;
    }
    if (!inputValue.flexiblePrice.trim()) {
      newErrors.flexiblePrice = "Please enter flexible price";
      isValid = false;
    }
    if (!inputValue.image) {
      newErrors.image = "Please upload an image";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCreateProduct = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("image", inputValue.image as File);
      formData.append("name", inputValue.name);
      formData.append("brand", inputValue.brand);
      formData.append("description", inputValue.description);
      formData.append("stock", inputValue.stock);
      formData.append("outrightPrice", inputValue.outrightPrice);
      formData.append("flexiblePrice", inputValue.flexiblePrice);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/createProduct`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setShowSuccess(true);
        toast.success("Product added successfully");
        handleCancel();
      } catch (error: any) {
        toast.error(error.response?.data?.error || "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setInputValue({
      name: "",
      brand: "",
      description: "",
      stock: "",
      outrightPrice: "",
      flexiblePrice: "",
      image: null,
    });
    setErrors({});
    closeModal();
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
        <div className="relative w-11/12 max-w-lg">
          <div className="bg-white rounded-lg shadow-lg">
            <div className="flex justify-between items-center p-5 border-b">
              <h2 className="text-lg font-bold">Add Product</h2>
              <GoXCircle
                className="text-red-500 cursor-pointer"
                size={24}
                onClick={closeModal}
              />
            </div>
            <form
              className="p-6"
              onSubmit={handleCreateProduct}
              encType="multipart/form-data"
            >
              {[
                { label: "Product Name", name: "name", type: "text" },
                { label: "Description", name: "description", type: "text" },
                { label: "Brand", name: "brand", type: "text" },
                { label: "Stock", name: "stock", type: "number" },
                { label: "Outright Price", name: "outrightPrice", type: "number" },
                { label: "Flexible Price", name: "flexiblePrice", type: "number" },
              ].map((field) => (
                <div className="mb-4" key={field.name}>
                  <label
                    htmlFor={field.name}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    value={
                      field.type !== "file"
                        ? inputValue[field.name as keyof typeof inputValue] as string
                        : undefined 
                    }
                    onChange={handleInputChange}
                    className="w-full mt-1 p-2 border rounded"
                    placeholder={field.type !== "file" ? `Enter ${field.label.toLowerCase()}` : undefined}
                  />

                 
                  {errors[field.name as keyof Errors] && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors[field.name as keyof Errors]}
                    </p>
                  )}
                </div>
              ))}
              <div className="mb-4">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  Product Image
                </label>
                <input
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded"
                />
                {errors.image && (
                  <p className="text-red-500 text-xs mt-1">{errors.image}</p>
                )}
              </div>
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-200 rounded text-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  {isLoading ? "Adding..." : "Add Product"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};
