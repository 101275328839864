import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const Back = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  }

  return (
    <div onClick={goBack}>
      <div className="bg-gray-100 rounded-[50%] shadow px-2 py-2 w-fit cursor-pointer">
       <IoIosArrowRoundBack  className="w-6 h-6 text-secondary"/>
      </div>
    </div>
  );
};

export default Back;
