import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllCustomers, migrateCustomers } from "../thunk";

interface customersState {
  customers: any[];
  status: string;
  error: string;
}

const initialState: customersState = {
  customers: [],
  status: 'idle',
  error: ''
}

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getAllCustomers.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.status = "success"
        state.customers = action.payload;
        state.error = 'nil'
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
      //migrate customers
      .addCase(migrateCustomers.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(migrateCustomers.fulfilled, (state) => {
        state.status = "success"
        state.error = 'nil'
      })
      .addCase(migrateCustomers.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default customerSlice.reducer
