import { useState } from "react";
import { Calender } from "../../components/elements/Calender";
import { PageTemplate } from "../PageTemplate";
import { startOfDay } from "date-fns";
import Payment from "./Payment";

export const MarketerPayment = () => {
  const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
  const [customerId, setCustomerId] = useState([]);

  const [modals, setModals] = useState({
    calendar: false,
  });

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return (
    <>
      <PageTemplate tab="Payments">
        <div className="bg-[#F6F4F8] px-4 lg:px-8 pb-8 md:h-full h-fit flex flex-col py-5">
          <section className="">
            <Payment openModal={() => openModal("calendar")} setCustomerId={setCustomerId} />
            <div className="relative">
            {modals.calendar && (
              <Calender
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                closeModal={() => closeModal("calendar")}
                customerId={customerId}
              />
            )}

            </div>
          </section>
        </div>
      </PageTemplate>
    </>
  );
};
