import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getMarketersWithCustomers } from "../thunk";

interface marketers {
  customerCount: number;
  fullName: string;
  _id: string;
  name: string;
}

interface MarketerState {
  marketers: marketers[];
  status: string;
  error: string;
}

const initialState: MarketerState = {
  marketers: [],
  status: "idle",
  error: ""
}

const marketerSlice = createSlice({
  name: "marketers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketersWithCustomers.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getMarketersWithCustomers.fulfilled, (state, action: PayloadAction<marketers[]>) => {
        state.status = "success"
        state.marketers = action.payload;
        state.error = 'nil'
      })
      .addCase(getMarketersWithCustomers.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default marketerSlice.reducer
