import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllMarketers } from "../thunk";

interface allMarketer {
  email: string;
  fullName: string,
  image: string,
  _id: string
}

interface allMarketerState {
  allMarketer: allMarketer[];
  status: string;
  error: string;
}

const initialState: allMarketerState = {
  allMarketer: [],
  status: "idle",
  error: ""
}

const allMarketerSlice = createSlice({
  name: "allMarketer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMarketers.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getAllMarketers.fulfilled, (state, action: PayloadAction<allMarketer[]>) => {
        state.status = "success"
        state.allMarketer = action.payload;
        state.error = 'nil'
      })
      .addCase(getAllMarketers.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default allMarketerSlice.reducer
