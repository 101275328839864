import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { checkoutSell } from "../thunk";

interface Order {
  customerAccountNo: string;
  otp: string;
  totalPrice: number;
  savedOrder: any,
  customer: any
}
interface OrderState {
  order: Order;
  status: string;
  error: string;
}

export type CheckoutModalProps = {
  order: Order;
  name: string;
  setShowCheckoutModal: (value: boolean) => void;
  setShowSuccess: (value: boolean) => void;
};
const initialState: OrderState = {
  order: {} as Order,
  status: "idle",
  error: "",
};

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkoutSell.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(
        checkoutSell.fulfilled,
        (state, action: PayloadAction<Order>) => {
          state.status = "success";
          state.order = action.payload;
          state.error = "nil";
        }
      )
      .addCase(checkoutSell.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default purchaseSlice.reducer;
