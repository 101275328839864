import React from "react";

const ChatAdminCard = () => {
  return (
    <main className="flex items-center justify-center bg-white rounded-md shadow-md p-6 h-[200px] w-[60vh] flex-col cursor-pointer hover:bg-secondary100">
      <img
        src="assets/admin.png"
        alt="admin"
        className="w-12 h-12 rounded-[50%] self-center border border-secondary"
      />
      <h1 className="text-lg self-center">Chat with Admin</h1>
    </main>
  );
};

export default ChatAdminCard;
