import { GoXCircle } from "react-icons/go";
import { MarketersModalProps } from "../../../types/types";

export const MarketersModal = ({
  name,
  email,
  address,
  staffID,
  phoneNo,
  setShowModal,
  image
}: MarketersModalProps) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="justify-center sm:items-center items-end flex overflow-x-hidden overflow-y-auto fixed h-full inset-0 z-50 outline-none focus:outline-none bottom-0">
        <div className="relative h-5/6 sm:my-6 sm:mx-auto sm:max-w-lg sm:w-96 w-60">
          <div className="border-0 rounded-t-md sm:rounded-lg shadow-lg relative flex flex-col w-full sm:w-96 bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h2 className="font-bold">Marketer Details</h2>
              <GoXCircle onClick={handleClose} />
            </div>
            <section className="relative px-6 py-2">
            <div className="relative w-24 h-24 border bg-blue-200 border-blue-200 rounded-full flex items-center mb-5">
              <img
                className="object-cover rounded-full w-full h-full"
                src={image}
                alt=""
              />
              </div>
              <span className="">
                <label className="block font-medium text-xs" htmlFor="name">
                  Full name
                </label>
                <input
                  className="block border w-full mt-1 p-2 text-xs bg-secondary100 rounded font-semibold mb-5"
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  disabled
                />
              </span>
              <span>
                <label className="block font-medium text-xs" htmlFor="email">
                  Email
                </label>
                <input
                  className="block border w-full mt-1 p-2 text-xs bg-secondary100 rounded font-semibold mb-5"
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  disabled
                />
              </span>

              <span>
                <label htmlFor="phoneNo" className="block font-medium text-xs">
                  Phone number
                </label>
                <input
                  type="text"
                  className="block border w-full mt-1 p-2 text-xs bg-secondary100 rounded font-semibold mb-5"
                  name="phoneNo"
                  id="phoneNo"
                  value={phoneNo}
                  disabled
                />
              </span>
              <span>
                <label htmlFor="address" className="block font-medium text-xs">
                  Home address
                </label>
                <input
                  type="text"
                  className="block border w-full mt-1 p-2 text-xs bg-secondary100 rounded font-semibold mb-5"
                  name="address"
                  id="address"
                  value={address}
                  disabled
                />
              </span>
              <span>
                <label htmlFor="staffid" className="block font-medium text-xs">
                  Staff ID
                </label>
                <input
                  type="text"
                  className="block border w-full mt-1 p-2 text-xs bg-secondary100 rounded font-semibold mb-5"
                  name="staffid"
                  id="staffid"
                  value={staffID}
                  disabled
                />
              </span>
            </section>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
