import { useState, useEffect } from "react";
import { AdminCustomerTableProps, CustomerTableProps } from "../types/types";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import axios from "axios";
import Button from "./elements/Button";
import { IoAddSharp } from "react-icons/io5";

const AdminCustomersTable = ({
  openCustomerModal,
  openCalender,
  setCustomerId
}: AdminCustomerTableProps) => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [customers, setCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getCustomersHandler()
  }, [])

  const getCustomersHandler = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getCustomersSalesHistory`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
     
      setCustomers(response.data);
      setIsLoading(false)
      console.log(response.data)
    } catch (error: any) {
      setCustomers([]);
      setIsLoading(false)
    }
  };
  return (
    <main className="overflow-x-auto w-full">
      <div className={`bg-white rounded-md shadow-md ${isLoading && "animate-pulse h-[60vh]"}`}>
        <div className="w-full overflow-x-auto">
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">Customers</h1>
            <Button
              onClick={openCustomerModal}
              className="mr-3 flex items-center border text-sm border-black text-black py-2 px-6 cursor-pointer hover:text-gray-700 hover:border-gray-700"
            >
              <IoAddSharp className="mr-2" /> Add new customer
            </Button>
          </div>
          <table className="w-full" style={{ minWidth: "700px" }}>
            <thead className="bg-gray-50">
              <tr>
                <th className="p-4 border-b">S/N</th>
                <th className="p-4 border-b">Name</th>
                <th className="p-4 border-b">Total Loan</th>
                <th className="p-4 border-b">Total Paid</th>
                <th className="p-4 border-b">Total Left</th>
                <th className="p-4 border-b">Marketer's name</th>
                <th className="p-4 border-b">Daily pay</th>
                <th className="p-4 border-b">Calender</th>
              </tr>
            </thead>
            <tbody>
            {/* {
              customers.length === 0 && 
              <div className="animate-pulse">
                <img src="/assets/search.png" alt="" />
                <p className="italic text-gray-600">No Customers</p>
              </div>
            } */}
                 {customers.map((customer: any, index) => {
                  const totalRemaining = customer.events.reduce(
                    (acc: number, event: any) => acc + event.totalAmount,
                    0
                  );
                  const totalAmount = customer.orders.reduce(
                    (acc: number, order: any) => acc + order.totalPrice,
                    0
                  );
                  const totalPaid = totalAmount - totalRemaining;
                  return (
                <tr className="text-center border-b-[1px]" key={index}>
                   <td className="p-4 font-semibold text-secondary">
                    {index + 1}
                  </td> 
                  <td className="p-4">
                    {customer.fullName}
                  </td>
                  <td className="p-4">{totalAmount.toLocaleString("en-US")}</td>
                  <td className="p-4 ">{totalPaid.toLocaleString("en-US")}</td>
                  <td className="p-4 ">{totalRemaining.toLocaleString("en-US")}</td>
                  <td className="p-4 ">{customer.marketerName}</td>
                  <td className="p-4 ">{customer.events[0]?.dailyAmount}</td>
                  <td className="p-4 cursor-pointer" onClick={() => {openCalender()
                  setCustomerId(customer.events);
                }}>
                    View Calender
                  </td>
                </tr>
                 )})}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default AdminCustomersTable;
