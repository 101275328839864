import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import SideBar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import MarketerDetails from "../../components/MarketerDetails";
import SalesHistory from "../../components/SalesHistory";
import TodayPayment from "../../components/TodayPayment";
import Navbar2 from "../../components/Navbar2";
import LogoutModal from "../../components/LogoutModal";
import DisputeModal from "../../components/DisputeModal";
import AddNewCustomerModal from "../../components/AddNewCustomerModal";
import SuccessModal from "../../components/SuccessModal";
import ProductsModal from "../../components/ProductsModal";
import { Calender } from "../../components/elements/Calender";
import { startOfDay } from "date-fns";
import AdminDetails from "../../components/AdminDetails";
import AdminCustomersTable from "../../components/AdminCustomersTable";
import { AddProductModal } from "../Admin/AddProductModal";
import { Success } from "../../components/Store/Success";

const Dashboard = () => {
  const [tabNavigation, setTabNavigation] = useState(false);
  const [customerAcctNo, setCustomerAcctNo] = useState("");
  const [customerId, setCustomerId] = useState([]);
  const [productsObj, setProductsObj] = useState({});
  const [modals, setModals] = useState({
    logout: false,
    dispute: false,
    addCustomer: false,
    success: false,
    products: false,
    calendar: false,
    addProduct: false,
  });
  const { role } = useAppSelector((store: RootState) => store.auth);
  const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
  const [showSuccess, setShowSuccess] = useState(false);

  const displayModal = (role: string | null) => {
    switch (role) {
      case "admin":
        return "addProduct";
      default:
        return "dispute";
    }
  };

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return (
    <div className="flex w-full">
      <SideBar
        tabNavigation={tabNavigation}
        toggleTabNavigation={toggleTabNavigation}
        openModal={() => openModal("logout")}
      />
      <div className="container ml-0 lg:ml-[20%] w-full ">
        <Navbar
          toggleTabNavigation={toggleTabNavigation}
          tabNavigation={tabNavigation}
        />
        <Navbar2
          tab="Dashboard"
          openModal={() => openModal(displayModal(role))}
        />
        <div className="bg-[#F6F4F8] py-1 px-4 lg:px-6 2xl:px-8 pb-8">
          {modals.logout && (
            <LogoutModal closeModal={() => closeModal("logout")} />
          )}
          {modals.dispute && (
            <DisputeModal closeModal={() => closeModal("dispute")} />
          )}
          {modals.addCustomer && (
            <AddNewCustomerModal
              closeModal={() => closeModal("addCustomer")}
              openModal={() => openModal("success")}
              setCustomerAcctNo={setCustomerAcctNo}
            />
          )}
          {modals.success && (
            <SuccessModal
              closeModal={() => closeModal("success")}
              customerAcctNo={customerAcctNo}
            />
          )}
          {modals.products && (
            <ProductsModal closeModal={() => closeModal("products")} productsObj={productsObj}/>
          )}
           {modals.calendar && (
                 <Calender
                   selectedDate={selectedDate}
                   setSelectedDate={setSelectedDate}
                   closeModal={() => closeModal("calendar")}
                   customerId={customerId}
                 />
               )}
          {modals.addProduct && (
            <AddProductModal
              setShowSuccess={setShowSuccess}
              openModal={() => openModal("addProduct")}
              closeModal={() => closeModal("addProduct")}
            />
          )}
          {showSuccess ? (
            <Success
              heading="Product added!"
              details=""
              setShowSuccess={setShowSuccess}
            />
          ) : null}

          {role === "marketer" ? <MarketerDetails /> : <AdminDetails />}
          {role === "marketer" ? (
             <section className="flex items-start justify-between flex-col xl:flex-row w-full">
             <div className="w-full xl:w-[65%]">
               <SalesHistory
                 openModal={() => openModal("addCustomer")}
                 openSuccessModal={() => openModal("success")}
                 openProductModal={() => openModal("products")}
                 openCalendarModal={() => openModal("calendar")}
                 setProductsObj={setProductsObj}
                 setCustomerId={setCustomerId}
               />
             </div>
             <div className=" w-full xl:w-[33%] mt-6 xl:mt-0">
               <TodayPayment openModal={() => openModal("calendar")} />
             </div>
           </section>
          ) : (
            <section className="flex items-start justify-between flex-col xl:flex-row w-full">
            <AdminCustomersTable
              openCalender={() => openModal("calendar")}
              openCustomerModal={() => openModal("addCustomer")}
              setCustomerId={setCustomerId}
            />
          </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
