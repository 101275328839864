import { GoCalendar } from "react-icons/go";
import { TodayPaymentProps } from "../types/types";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { getAllCustomers, getPaymentEvents } from "../redux/thunk";
import { Success } from "./Store/Success";
import LoadingSpinner from "./elements/LoadingSpinner";

const TodayPayment = ({ openModal }: TodayPaymentProps) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { event } = useAppSelector((store: RootState) => store.event);
  const { customers, status } = useAppSelector(
    (store: RootState) => store.customers
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (event.length === 0) {
      dispatch(getPaymentEvents());
    }
  }, [dispatch, event.length]);

  const getDate = () => {
    const today = new Date();
    const dataTime = `${today.getDate().toString().padStart(2, "0")}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${today.getFullYear()}`;
    return dataTime;
  };

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getAllCustomers());
    }
  }, [customers.length, dispatch]);

  const findCustomer = (id: string) => {
    const customer = customers.find((c) => c._id === id);
    return customer
      ? {
          fullName: customer.fullName,
          customerAccountNo: customer.customerAccountNo,
        }
      : { fullName: "", customerAccountNo: "" };
  };

  return (
    <main className="overflow-x-auto">
      <div className="bg-white rounded-md shadow-md p-6">
        <div className="flex items-center justify-between pb-4">
          <h1 className="text-base font-semibold ">Today's Payment</h1>
          <div className="flex items-center border text-sm border-secondary200 py-2 px-5 rounded-md shadow-sm">
            <p>{getDate()}</p>
          </div>
        </div>
        {status === "loading" ? (
          <div className="flex justify-between h-20">
            <LoadingSpinner />
          </div>
        ) : (
          event.map((d) => (
            <section key={nanoid()}>
              <div className="flex items-center justify-between py-2">
              <div>
                  <h1 className="font-semibold ">
                    {findCustomer(d.customerId).fullName}
                  </h1>
                  <p className="text-gray-700">
                    {findCustomer(d.customerId).customerAccountNo}
                  </p>
                </div>
                <div
                  onClick={openModal}
                  className="flex items-center cursor-pointer bg-secondary100 text-secondary border-secondary200 outline-none py-2 px-4 border text-sm rounded-md shadow-sm"
                >
                  <GoCalendar className="w-4 h-4" />
                  <p className="pl-2">View dates</p>
                </div>{" "}
              </div>
            </section>
          ))
        )}
      </div>
      {showSuccess ? (
        <Success
          heading="Successful purchase!"
          details="The Purchase was successful!!"
          setShowSuccess={setShowSuccess}
        />
      ) : null}
    </main>
  );
};

export default TodayPayment;
