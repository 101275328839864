import { useNavigate } from "react-router-dom";

const Nav = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-between text-sm lg:flex-row h-96 lg:h-0 ">
             <li>
                <p  onClick={() => navigate("/")} className="mr-0 lg:mr-4 cursor-pointer active:text-secondary">Home</p>
            </li>
            <li >
                <p onClick={() => navigate("/about")} className="mr-0 lg:mr-4 cursor-pointer active:text-secondary">About</p>
            </li>
            <li>
                <p onClick={() => navigate("/services")} className="mr-0 lg:mr-4 cursor-pointer active:text-secondary">Services</p>
            </li>
             <li>
                <p onClick={() => navigate("/product")} className="mr-0 lg:mr-4 cursor-pointer active:text-secondary">Catalog</p>
            </li>
           {/* <li>
                <p onClick={() => navigate("/login")} className="mr-0 lg:mr-4 cursor-pointer active:text-secondary">Login</p>
            </li> */}
            <li>
                <p onClick={() => navigate("/contact")} className="mr-0 lg:mr-4 cursor-pointer bg-secondary text-white hover:bg-purple-950 py-2 px-5 rounded-md shadow-md active:text-secondary">Contact</p>
            </li>
        </div>
    );
};

export default Nav;