import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Testimonial from "../../components/Testimonial";
import InformationCard from "../../components/InformationCard";

const AboutUS = () => {
  return (
    <div className=" bg-secondary100 ">
      <div className=" bg-grey flex justify-center py-4 ">
        <Header />
      </div>
      <div className="flex items-center justify-center  py-[4rem]  lg:py-[6rem]">
        <div className="w-[90%]">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-[50%] mb-8 lg:mb-0" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1500">
              <h1 className="text-base font-bold leading-5">About us</h1>
              <h1 className="text-[32px] lg:text-[56px] leading-9 lg:leading-10 font-bold py-4 lg:py-6">
                Who we are
              </h1>
              <p className="text-base leading-6 w-[80%]">
                "Waygibb is dedicated to making the latest tech accessible and affordable for everyone. We provide a flexible, loan-based approach to owning premium gadgets, from smartphones and laptops to gaming devices and more. With easy registration, tailored payment plans, and immediate access to products, we eliminate the barriers to top-tier technology. Our mission is to empower individuals by offering a hassle-free experience, allowing you to stay connected, productive, and entertained—all on terms that fit your lifestyle and budget."
              </p>
            </div>
            <div className="w-full lg:w-[50%]" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500">
              <div className="flex lg:items-end lg:justify-end">
                <img src="/assets/about-picture.png" alt="person" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex items-center justify-center bg-white py-16">
        <p className="text-base leading-8 w-[85%] lg:w-[60%] lg:text-center">
          "At Waygibb, we keep it simple and convenient to access the technology you need. First, register with one of our knowledgeable marketers, who will guide you through each step. Then, explore our selection of gadgets and pick the perfect device for your needs. Choose from flexible payment plans designed to fit various budgets, allowing you to make affordable daily, weekly, or monthly payments. Finally, get your gadget delivered right away, so you can start enjoying it immediately, stress-free!"
        </p>
      </div>

      <Testimonial />
      <InformationCard />
      <Footer />
    </div>
  );
};

export default AboutUS;
