import { Product } from "./product";

interface ProductCardProps {
  product: Product;
}
const formatPrice = (price: number | string): string => {
  if (!price) return "0";
  return new Intl.NumberFormat("en-US").format(Number(price));
};
export const ProductCard = ({ product }: ProductCardProps) => {
  const { img, name, description, outright, flexible } = product;

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 w-full sm:w-80 mx-auto">
      <img
        src={img}
        alt={name}
        className="w-full h-48 object-cover rounded-md mb-4"
      />
      <div className="bg-blue-400 h-px my-4"></div>
      <h3 className="text-lg font-semibold mb-2 truncate">{name}</h3>
      <p className="text-gray-600 text-sm mb-2">{description}</p>
      
      <p className="text-sm mb-1">
        <span className="text-gray-500">Outright Price:</span>{" "}
        <span className="font-semibold">₦{formatPrice(outright)}</span>
      </p>
      <p className="text-sm mb-3">
        <span className="text-gray-500">Flexible Price:</span>{" "}
          <span className="font-bold">#{formatPrice(flexible)}</span>{" "}
          <span className="text-gray-500">(Weekly/Monthly payment)</span>
      </p>
     
    </div>
  );
};
