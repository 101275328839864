import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProducts = createAsyncThunk<any>(
  "products",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_BASE_URL}/getAllProducts`
      );
      const sortedProducts = response.data.sort((a: any, b: any) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });
      
      return sortedProducts;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCustomersHandler = createAsyncThunk(
  "customers",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllCustomers`, {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMarketersWithCustomers = createAsyncThunk(
  "MarketersWithCustomers",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getMarketerAndCustomer`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCustomers = createAsyncThunk(
  "GetAllCustomers",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllMarketers = createAsyncThunk(
  "GetAllMarketers",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllMarketer`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllHelpCenter = createAsyncThunk(
  "GetAllHelpCenter",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllHelpCenter`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCart = createAsyncThunk(
  "GetCart",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllOrdersByMarketerId`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPaymentEvents = createAsyncThunk(
  "GetPaymentEvents",
  async (_, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getEvents`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const migrateCustomers = createAsyncThunk(
  "migrateCustomers",
  async (data: {customerIds: string[], marketerId: string}, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/migrateCustomers`, data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;    
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const payDaily = createAsyncThunk(
  "PayDaily",
  async (data: {eventId: string, amountPaid: number}, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/payDaily`, data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;    
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkoutSell = createAsyncThunk(
  "checkoutSell",
  async (data: any, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ordersell`, data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;    
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const purchaseOrder = createAsyncThunk(
  "PurchaseOrder",
  async (data: {
    customerAccountNo: string,
    otp: string
  }, thunkAPI) => {
    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/purchaseOrder`, data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;    
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        credentials
      );
      sessionStorage.setItem('image', response.data.user.image);
      sessionStorage.setItem("name", response.data.user.fullName);
      sessionStorage.setItem("userEmail", response.data.user.email);
      const endpoint =  `${process.env.REACT_APP_API_BASE_URL}/login`
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
