import { GoXCircle } from "react-icons/go";
import { ItemModalProps } from "../../types/types";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { checkoutSell } from "../../redux/thunk";
import toast from "react-hot-toast";
import { Preloader } from "../elements/Preloader";

export const ItemModal = ({
  status,
  productId,
  img,
  name,
  description,
  outright,
  flexible,
  setShowModal,
  setShowCheckoutModal,
  
}: ItemModalProps) => {
  const { role } = useAppSelector((store: RootState) => store.auth);

  const [itemCount, setItemCount] = useState(1);
  const [payFlexible, setPayFlexible] = useState(false);
  const [acctNo, setAcctNo] = useState("");
  const [inputFilled, setInputFilled] = useState(false);
  const [loanTerm, setLoanTerm] = useState(1);
  const [interestRate, setInterestRate] = useState(1);
  const [dailyAmt, setDailyAmt] = useState(0);

  const dispatch = useAppDispatch();

  const data = {
    items: [
      {
        productId: productId,
        quantity: itemCount,
        dailyPayBack: dailyAmt,
      },
    ],
    customerAccountNo: acctNo,
    orderType: payFlexible ? "flexible" : "outright",
    interestRate: interestRate,
    loanTerm: loanTerm,
  };

  const data2 = {
    items: [
      {
        productId: productId,
        quantity: itemCount,
      },
    ],
    customerAccountNo: acctNo,
    orderType: payFlexible ? "flexible" : "outright",
  }

  const addCart = () => {
    if (!acctNo) {
      setInputFilled(true);
      return;
    }
    handleClose();

    const selectedData = payFlexible ? data : data2;
    dispatch(checkoutSell(selectedData)).then(() => {
      toast.success("Added to cart!")
    })
    .catch((error: any) => {
      console.error("An error occurred while adding to cart:", error);
      toast.error("Carting failed!")
    });
  };
  const handleClose = () => {
    setShowModal(false);
  };


  const handleQty = (operation: string) => {
    setItemCount((prev) => {
      if (operation === "increment") {
        return prev + 1;
      } else if (operation === "decrement" && prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  
  const handleRadioChange = (e: { target: { id: string } }) => {
    setPayFlexible(e.target.id === "sell-flexible");
  };

  const handleCheckout = () => {
    if (!acctNo) {
      setInputFilled(true);
      return;
    }

    const selectedData = payFlexible ? data : data2;
    dispatch(checkoutSell(selectedData))
      .then(() => {
        setShowModal(false);
        setShowCheckoutModal(true);
      })
      .catch((error: any) => {
        console.error("An error occurred while adding to cart:", error);
        toast.error("An error occurred!");
      });
  };

  return (
    <>
      <div className="justify-center sm:items-center items-end flex overflow-x-hidden overflow-y-auto fixed h-full inset-0 z-50 outline-none focus:outline-none bottom-0">
        <div className="relative h-5/6 sm:w-auto sm:my-6 sm:mx-auto sm:max-w-lg w-80">
          <div className="border-0 rounded-t-sm sm:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between px-5 pt-5 rounded-t">
              <h2 className="font-bold">
                {role === "admin" ? "Gadget Details" : "Sell Gadget"}
              </h2>
              <GoXCircle onClick={handleClose} />
            </div>

            <section className="relative px-4 py-2">
              <section>
                <div className="sm:flex border items-start rounded-lg p-2 w-full">
                  <div
                    className={`flex ${
                      role === "admin"
                        ? "flex-col items-center w-full"
                        : "flex-row p-2"
                    }`}
                  >
                    <div className={`${role === "admin" ? "w-1/2" : "mr-2"}`}>
                      <img className=" rounded-lg" src={img} alt={name} />
                    </div>
                    <div>
                      <input
                        className="font-medium text-sm bg-transparent"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        disabled
                      />
                      <p
                        className={`text-xs text-gray-500 ${
                          role === "admin" ? "w-full" : "w-56"
                        }`}
                      >
                        {description}
                      </p>
                    </div>
                  </div>
                  {role === "marketer" ? (
                    <div className="flex items-center w-fit border rounded-lg">
                      <input
                        type="hidden"
                        name="item quantity"
                        value={itemCount}
                      />
                      <button
                        className="border-r w-7"
                        type="button"
                        onClick={() => handleQty("decrement")}
                      >
                        -
                      </button>
                      <span className="w-7 text-center text-xs">
                        {itemCount}
                      </span>
                      <button
                        className="border-l w-7"
                        type="button"
                        onClick={() => handleQty("increment")}
                      >
                        +
                      </button>
                    </div>
                  ) : null}
                </div>
                <br />
                <div className="flex sm:flex-row flex-col justify-between">
                  <label
                    htmlFor="sell-outright"
                    className={`relative rounded-lg border p-4 mb-3 sm:w-52 ${
                      !payFlexible ? "border-green-600" : null
                    }`}
                  >
                    <img
                      className={`absolute rounded-lg top-0 right-0 ${
                        !payFlexible ? "block" : "hidden"
                      }`}
                      src="assets/checked.jpg"
                      alt="checked"
                    />
                    <input
                      className="float-right appearance-none bg-white w-5 h-5 border rounded-2xl"
                      type="radio"
                      name="sell"
                      id="sell-outright"
                      value={outright}
                      checked={!payFlexible}
                      onChange={handleRadioChange}
                    />
                    <p className="text-xs mb-3">Sell outright</p>
                    <p className="text-sm font-semibold">#{Number(outright) * itemCount}</p>
                   
                  </label>
                  <label
                    htmlFor="sell-flexible"
                    className={`relative rounded-lg border p-4 mb-3 sm:w-52 ${
                      payFlexible ? "border-green-600" : null
                    }`}
                  >
                    <img
                      className={`absolute rounded-lg top-0 right-0 ${
                        payFlexible ? "block" : "hidden"
                      }`}
                      src="assets/checked.jpg"
                      alt="checked"
                    />
                    <input
                      className="float-right appearance-none bg-white w-5 h-5 border rounded-2xl"
                      type="radio"
                      name="sell"
                      id="sell-flexible"
                      value={flexible}
                      checked={payFlexible}
                      onChange={handleRadioChange}
                    />
                    <p className="text-xs mb-3">Sell flexible</p>
                    <p className="text-sm font-semibold">#{Number(flexible) * itemCount}</p>
                  </label>
                </div>
                {role === "marketer" && payFlexible ? (
                  <div className="my-5">
                    <h2 className="font-bold text-sm">Payment Structure</h2>
                    <p className="text-xs leading-8">
                      Amount: <span className="font-semibold">#{Number(flexible) * itemCount}</span>
                    </p>
                    <label className="text-xs leading-8 block">
                      Loan Term:{" "}
                      <input
                        className="font-bold text-xm w-10"
                        type="number"
                        min={1}
                        name=""
                        id=""
                        value={loanTerm}
                        onChange={(e) => setLoanTerm(Number(e.target.value))}
                      />{" "}
                      (days)
                    </label>
                    <label className="text-xs leading-8 block">
                      Interest rate:{" "}
                      <input
                        type="number"
                        min={1}
                        className="font-semibold w-10"
                        name=""
                        id=""
                        value={interestRate}
                        onChange={(e) =>
                          setInterestRate(Number(e.target.value))
                        }
                      />
                      (% annually)
                    </label>
                    <label className="text-xs leading-8">
                      Monthly payment frequency: Daily
                    </label>
                    <div>
                      <label htmlFor="dailyAmt" className="font-medium text-xs">
                        Input daily amount
                      </label>
                      <span
                        className={`text-xs text-red-500 ${
                          inputFilled ? "block" : "hidden"
                        }`}
                      >
                        **Please enter daily amount.
                      </span>
                      <input
                        type="text"
                        className="rounded-lg block border w-full mt-1 p-2 bg-stone-50 text-xs"
                        name="dailyAmt"
                        id="dailyAmt"
                        placeholder="Enter daily amount"
                        onChange={(e) => setDailyAmt(Number(e.target.value))}
                      />
                    </div>
                  </div>
                ) : null}
                {role === "marketer" ? (
                  <>
                    <div>
                      <label htmlFor="acct-no" className="font-medium text-xs">
                        Account Number
                      </label>
                      <span
                        className={`text-xs text-red-500 ${
                          inputFilled ? "block" : "hidden"
                        }`}
                      >
                        **Please enter your account number.
                      </span>
                      <input
                        type="text"
                        className="rounded-lg block border w-full mt-1 p-2 bg-stone-50 text-xs"
                        name="account number"
                        id="acct-no"
                        placeholder="Enter account number"
                        onChange={(e) => setAcctNo(e.target.value)}
                      />
                    </div>
                    <br />
                    <div className="md:flex gap-5">
                      <button
                        className="bg-violet-950 rounded text-white py-3 md:mb-6 mb-3 w-full text-xs font-medium"
                        type="button"
                        onClick={addCart}
                      >
                        Add to cart
                      </button>
                      <button
                        className="bg-violet-950 rounded text-white py-3 md:mb-6 mb-3 w-full text-xs font-medium"
                        type="button"
                        onClick={handleCheckout}
                      >
                        {status === "loading" ? <Preloader /> : 'Checkout' }
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    className="bg-violet-950 rounded text-white py-3 md:mb-6 mb-3 w-full text-xs font-medium"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Back to store
                  </button>
                )}
              </section>
            </section>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
