import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./products/productsSlice";
import cartReducer from "./cart/cartSlice";
import authReducer from "./auth/authSlice";
import marketerReducer from "./marketers/marketerSlice"
import customersReducer from "./customers/customerSlice"
import helpCenterReducer from "./helpCenter/helpCenterSlice";
import purchaseReducer from "./purchase/purchaseSlice";
import eventReducer from "./events/eventSlice";
import allMarketerReducer from "./marketers/allMarketerSlice";

const store = configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    auth: authReducer,
    marketers: marketerReducer,
    customers: customersReducer,
    helpCenter: helpCenterReducer,
    purchase: purchaseReducer,
    event: eventReducer,
    allMarketer: allMarketerReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
