import { useState, useEffect, MouseEventHandler } from "react";
import { GoArrowLeft } from "react-icons/go";
import { IoIosAdd } from "react-icons/io";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";

export const HelpCenter = () => {
  const [messageId, setMessageId] = useState<number | null>(null);
  const [msgVisible, setMsgVisible] = useState(false);
  const { token, uid } = useAppSelector((store: RootState) => store.auth);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  useEffect(() => {
    handleGetAllMessages();
  }, []);

  const toggleVisibility = () => {
    setMsgVisible((prev) => !prev);
  };

  const handleClick = (id: number) => {
    setMessageId((prevId) => (prevId === id ? prevId : id));
    toggleVisibility();
  };

  const handleGetAllMessages = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllMessages`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setMessages(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleReply = async (e: any) => {
    e.preventDefault();

    const payload = {
      adminId: uid,
      text: text,
      chatId: messageId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/helpMessage`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleGetAllMessages();
      setText("");
    } catch (error: any) {}
  };

  return (
    <>
      <section className="bg-[#F6F4F8] pt-1 h-full flex gap-1">
        <div
          className={`bg-white lg:w-96 lg:mx-0 mx-2 lg:p-5 p-2 w-screen md:block ${
            msgVisible ? "hidden" : "block"
          }`}
        >
          <div className="flex justify-between mb-3">
            <h3 className="font-semibold text-base">Dispute messages</h3>
            <div className="flex items-center gap-2">
              <span>
                <IoIosAdd className="text-secondary w-7 h-7" />
              </span>
              <span>
                <IoSearch className="text-secondary w-5 h-5" />
              </span>
            </div>
          </div>
          {isLoading ? (
            <div className="mt-16">
              <LoadingSpinner />
            </div>
          ) : (
            messages.map((message: any, index) => (
              <div
                key={index}
                className={`md:flex items-start gap-3 hover:bg-gray-100 cursor-pointer py-2 w-full ${
                  msgVisible ? "hidden" : "block"
                }`}
                onClick={() => {
                  handleClick(message._id);
                  setChats(message.chat);
                  setSubject(message.subject);
                }}
              >
                <div className="flex gap-3 items-center">
                  <div className="">
                    <div className="relative w-14 h-14 border bg-blue-200 border-blue-200 rounded-full flex items-center">
                      <img
                        className="object-cover rounded-full w-full h-full"
                        src="assets/picture.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="w-44">
                    <h3 className="font-semibold text-sm">{message.subject}</h3>
                    {message.chat.length > 0 && (
                      <p className="text-xs truncate">
                        {message.chat[message.chat.length - 1].text}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="w-14">
                <p className="text-xs font-semibold">5:45 PM</p>
              </div> */}
              </div>
            ))
          )}
        </div>
        {messageId !== null && chats && (
          <>
            <div
              className={`w-full relative md:block p-2 ${
                msgVisible ? "block" : "hidden"
              }`}
            >
              <div className="bg-white flex w-full gap-3 justify-between items-center px-10 py-2">
                <div onClick={toggleVisibility}>
                  <GoArrowLeft className="w-7 h-7" />
                </div>
                <div>
                  <h3 className="font-semibold">{subject}</h3>
                </div>
                <div className="relative w-14 h-14 border bg-blue-200 border-blue-200 rounded-full md:flex items-center hidden">
                  <img
                    className="object-cover rounded-full w-full h-full"
                    src="assets/picture.jpg"
                    alt=""
                  />
                </div>
              </div>
              {chats.map((chat: any, index: number) => (
                <section
                  className={`flex w-full ${
                    !chat.isMarketer && "items-end justify-end"
                  }`}
                >
                  <div
                    key={index}
                    className={`flex bg-${
                      chat.isMarketer ? "white" : "secondary text-white"
                    } p-4 ${
                      chat.isMarketer ? "rounded-r-3xl " : "rounded-l-3xl"
                    }  mt-5 max-w-[60%] px-6 `}
                  >
                    <p className="flex mb-2 text-xs">{chat.text}</p>
                  </div>
                </section>
              ))}
              <span className="fixed bottom-0 w-[40%] justify-between flex mb-2">
                <input
                  type="text"
                  name="msgReply"
                  id="msgReply"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="rounded p-1 w-5/6 py-4 border outline-none border-secondary100 focus:border-secondary200"
                />
                <button
                  className="rounded-r p-1 bg-secondary w-1/6 text-white text-sm hover:bg-purple-900"
                  onClick={handleReply}
                >
                  Send
                </button>
              </span>
            </div>
          </>
        )}
      </section>
    </>
  );
};
