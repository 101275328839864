import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isEqual,
  isSameMonth,
  isToday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
  isAfter,
  isBefore
} from "date-fns";
import { useMemo, useState, useEffect } from "react";
import { classNames } from "../micro/Calender";
import { CalenderProps } from "../../types/types";
import Modal, { ModalContent } from "./Modal";
import axios from 'axios';
import toast from "react-hot-toast";


const days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

export const Calender = ({
  selectedDate,
  setSelectedDate,
  setShowingDatePicker,
  sectionClasses,
  closeModal,
  customerId
}: CalenderProps) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [events, setEvents] = useState<any[]>([]);
  const [isMiniModalVisible, setIsMiniModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEvents(customerId);
  }, []);

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const newDays = useMemo(() => {
    return eachDayOfInterval({
      start: startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(currentMonth)),
    });
  }, [currentMonth]);

  const isDatePaid = (date: Date): boolean => {
    for (const event of events) {
      for (const paidDay of event.paidDays ?? []) {
        const normalizedDate = startOfDay(date);
        const normalizedPaidDay = startOfDay(new Date(paidDay.day));

        if (isEqual(normalizedDate, normalizedPaidDay)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleCurrentDateClick = (date: Date) => {
    if (isToday(date)) {
      setIsMiniModalVisible(true);
    }
    setSelectedDate(date);
    setShowingDatePicker?.(false);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(sessionStorage.getItem("authState") ?? '{}').token;

      const selectedEvent = events[0]; // Replace `0` with logic to select the right event if needed.
      if (selectedEvent && inputValue) {
        const response = await axios.put('https://gibbsbackend.onrender.com/v1/api/payDaily', {
          eventId: selectedEvent._id,
          amountPaid: inputValue,
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          }});
  
        console.log('Response:', response.data);
  
        if (response.data.success) {
          toast.success('Payment updated successfully');
          window.location.reload(); 
        } else if(response.data.success === false) {
          toast.error('Failed to update payment:', response.data.message);
        }
      } else {
        console.error('Event or input value is missing');
      }
    } catch (error) {
      toast.error('Error during form submission:');
    } finally {
      setLoading(false); // End loading
      setIsMiniModalVisible(false); // Close the mini modal after submission
      closeModal();
    }
  };

  return (
    <>
      {/* Mini modal */}
     

      <Modal open={true}  className="flex items-end lg:items-center justify-end">
      {isMiniModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-80">
          <button
                  onClick={() => closeModal()}
                  className="button-transparent shadow-sm text-grey- w-[28%] border-gray-200 p-[8px_14px_8px_14px] border rounded-[8px] "
                >
                <p>X</p>
                </button>
            <form onSubmit={handleFormSubmit}>
              <h2 className="text-lg font-semibold mb-4">Enter amount to Payment</h2>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter daily amount to pay"
                className="w-full p-2 border border-gray-300 rounded mb-4"
              />
              <button
                type="submit"
                className="w-full bg-secondary text-white p-2 rounded hover:bg-purple-900"
                disabled={loading}
              >
               {loading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )}
        <ModalContent className="mx-3 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
          <div
            className={classNames(
              "w-full py-[20px] sm:border shadow-lg border-gray-50  px-4  max-w-md gap-2 flex flex-col rounded-lg",
              sectionClasses
            )}
          >

            <header className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <button onClick={prevMonth}>&lt;</button>
                <h2 className="font-semibold leading-[24px] text-grey">
                  {format(currentMonth, "MMMM yyyy")}
                </h2>
                <button onClick={nextMonth}>&gt;</button>
                <button
                  onClick={() => closeModal()}
                  className="button-transparent shadow-sm text-grey- w-[28%] border-gray-200 p-[8px_14px_8px_14px] border rounded-[8px] "
                >
                <p>X</p>
                </button>
              </div>
              <div className="flex items-center justify-between ">
                <p className="w-[68%] text-grey shadow-sm border-gray-200 p-[8px_14px_8px_14px] border rounded-[8px]">
                  {format(selectedDate, "MMM d, yyyy")}
                </p>
                <button
                  onClick={() => {
                    setSelectedDate(startOfDay(new Date()));
                    setCurrentMonth(new Date());
                  }}
                  className="button-transparent shadow-sm text-grey- w-[28%] border-gray-200 p-[8px_14px_8px_14px] border rounded-[8px] "
                >
                  Today
                </button>
              </div>
            </header>
            <section className="flex flex-col">
              <header className="grid grid-cols-7 ">
                {days.map((day) => {
                  return (
                    <h3
                      key={day}
                      className="w-[40px] rounded-[20px] items-center justify-center flex h-[40px] font-medium text-grey text-[14px]"
                    >
                      {day}
                    </h3>
                  );
                })}
              </header>
              <ul className="grid grid-cols-7 grid-rows-5 gap-1">
                {newDays.map((date) => (
                  <li key={date.toISOString()}>
                    <button
                      onClick={() => handleCurrentDateClick(date)}
                      className={`w-[40px] cursor-pointer rounded-[20px] h-[40px] items-center justify-center flex relative
                    ${!isEqual(date, selectedDate) && "hover:bg-[#F9FAFB]"}
                     ${isEqual(date, selectedDate) && "border border-blue-400 "}
                    ${
                      isSameMonth(date, new Date())
                        ? "text-gray-600"
                        : "text-gray-400"
                    }
                    ${
                      !isDatePaid(date) && (isToday(date) || isAfter(date, new Date()))
                          ? "border-blue-500"
                          : isDatePaid(date) 
                            ? "bg-green-500 text-white"
                            : "bg-red-500 z-1 text-white"
                    }
                    ${format(date, "EEE") === "Sat" && isBefore(date, selectedDate)  && "bg-orange-500 z-10 text-white"}
                    ${format(date, "EEE") === "Sun" && isBefore(date, selectedDate) && "bg-orange-500 text-white"}`}
                    >
                      <time dateTime={format(date, "yyyy-MM-dd")}>
                        {format(date, "d")}
                      </time>
                      {isToday(date) && (
                        <div className="w-[5px] bottom-1 rounded-full bg-blue text-white  absolute h-[5px]" />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
