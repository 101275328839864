import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { products } from "../../types/types";

interface ProductsState {
  items: products[];  // List of products
  searchTerm: string;  // Search term for filtering products
}

const initialState: ProductsState = {
  items: [],  // Initialize as an empty array
  searchTerm: "", 
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<products[]>) {
      state.items = action.payload;
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;  // Updates search term
    },
  },
});

export const { setProducts, setSearchTerm } = productsSlice.actions;
export default productsSlice.reducer;
