import { useState } from "react";
import { ItemCardProps } from "../../types/types";
import { ItemModal } from "./ItemModal";
import { Success } from "./Success";
import { CheckoutModal } from "./CheckoutModal";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import axios from "axios";

export const ItemCard = ({
  productId,
  img,
  name,
  description,
  outright,
  flexible,
  onUpdate, // Callback to notify parent of updates
}: ItemCardProps & { onUpdate?: (id: string, updatedData: any) => void }) => {
  const { role } = useAppSelector((store: RootState) => store.auth);
  const { order, status } = useAppSelector((store: RootState) => store.purchase);

  const [showModal, setShowModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState<string | null>(null);

  const [updateData, setUpdateData] = useState({
    name,
    flexiblePrice: flexible,
    outrightPrice: outright,
    description,
  });

  // Format price utility
  const formatPrice = (price: number | string): string =>
    new Intl.NumberFormat("en-US").format(Number(price));

  // Delete product API call
  const deleteProduct = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deleteProduct/${productId}`);
      setIsDeleted(true);
      setDeleteMessage(`${name} has been successfully deleted.`);
    } catch (error) {
      console.error("Error deleting product:", error);
      setDeleteMessage("Failed to delete the product. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const confirmDelete = () => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      deleteProduct();
    }
  };

  // Update product API call
  const updateProduct = async () => {
    setIsUpdating(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/updateProduct/${productId}`, updateData);
      if (onUpdate) onUpdate(productId, updateData);
      setShowUpdateModal(false);
      setDeleteMessage("Update Successful.");
    } catch (error) {
      console.error("Error updating product:", error);
      setDeleteMessage("Failed to update the product. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({ ...prevData, [name]: value }));
  };

  if (isDeleted) return null;

  return (
    <section className="rounded-lg bg-white w-64 p-4 m-3">
      <div className="w-full h-40">
        <img className="w-full h-full rounded-xl" src={img} alt={name} />
      </div>
      <div>
        <p className="font-medium mb-3 truncate">{updateData.name}</p>
        <p className="text-xs mb-2">
          <span className="text-gray-500">Outright Price:</span>{" "}
          <span className="font-bold">₦{formatPrice(updateData.outrightPrice)}</span>
        </p>
        <p className="text-xs">
          <span className="text-gray-500">Flexible Price:</span>{" "}
          <span className="font-bold">₦{formatPrice(updateData.flexiblePrice)}</span>{" "}
          <span className="text-gray-500">(Monthly payment)</span>
        </p>
      </div>
      <button
        type="button"
        className="bg-violet-950 rounded text-white py-2 px-7 my-3 w-full text-xs font-medium"
        onClick={() => setShowModal(true)}
      >
        {role === "admin" ? "Details" : "Sell"}
      </button>
      {role === "admin" && (
        <div className="flex justify-between gap-2">
          <button
            type="button"
            className="bg-blue-500 rounded text-white py-2 px-4 w-full text-xs font-medium"
            onClick={() => setShowUpdateModal(true)}
          >
            Edit
          </button>
          <button
            type="button"
            className="bg-red-500 rounded text-white py-2 px-4 w-full text-xs font-medium"
            onClick={confirmDelete}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </button>
        </div>
      )}

      {showModal && (
        <ItemModal
          status={status}
          productId={productId}
          img={img}
          name={updateData.name}
          description={updateData.description}
          outright={updateData.outrightPrice}
          flexible={updateData.flexiblePrice}
          setShowModal={setShowModal}
          setShowCheckoutModal={setShowCheckoutModal}
        />
      )}

      {showCheckoutModal && (
        <CheckoutModal
          order={order}
          name={updateData.name}
          setShowCheckoutModal={setShowCheckoutModal}
          setShowSuccess={setShowSuccess}
        />
      )}

      {showSuccess && (
        <Success
          heading="Successful purchase!"
          details="Your purchase has been completed successfully."
          setShowSuccess={setShowSuccess}
        />
      )}

      {showUpdateModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Update Product</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateProduct();
              }}
            >
              <div className="mb-3">
                <label className="block text-sm font-medium">Name</label>
                <input
                  type="text"
                  name="name"
                  value={updateData.name}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </div>
              <div className="mb-3">
                <label className="block text-sm font-medium">Flexible Price</label>
                <input
                  type="number"
                  name="flexiblePrice"
                  value={updateData.flexiblePrice}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </div>
              <div className="mb-3">
                <label className="block text-sm font-medium">Outright Price</label>
                <input
                  type="number"
                  name="outrightPrice"
                  value={updateData.outrightPrice}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </div>
              <div className="mb-3">
                <label className="block text-sm font-medium">Description</label>
                <textarea
                  name="description"
                  value={updateData.description}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded w-full">
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </form>
            <button
              type="button"
              className="mt-3 bg-gray-500 text-white px-4 py-2 rounded w-full"
              onClick={() => setShowUpdateModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {deleteMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg text-center">
            <p>{deleteMessage}</p>
            <button
              type="button"
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => setDeleteMessage(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};
