import { useEffect, useState } from "react";
import { PageTemplate } from "../PageTemplate";
import { CartModal } from "../../components/CartModal";
import { Success } from "../../components/Store/Success";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { getCart } from "../../redux/thunk";

export const Cart = () => {
  const { cart, status } = useAppSelector((store: RootState)=> store.cart)
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [customerAcctNo, setCustomerAcctNo] = useState("")

  const handleClick = (id: string) => {
    setCustomerAcctNo(id)
    setShowModal((prev) => !prev);
  };

  useEffect(()=>{
      dispatch(getCart())
  }, [dispatch])
  
  return (
    <>
      <PageTemplate tab="Cart">
        <section className="bg-[#F6F4F8] px-4 lg:px-6 2xl:px-8 pt-2 pb-8 h-full">
          <div className="bg-white p-5">
            <table className="md:w-full w-[46rem] text-sm text-left rtl:text-right">
              <thead className={`bg-gray-100 ${status === "loading" && "animate-pulse"}`}>
                <tr>
                  <th className="px-2 py-3 font-medium">S/N</th>
                  <th className="px-6 py-3 font-medium">Name</th>
                  <th className="px-6 py-3 font-medium">Cart</th>
                </tr>
              </thead>
              <tbody>
                {
                cart.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td className="px-2 py-3">{key + 1}</td>
                      <td className="px-6 py-3">{val.customerName}</td>
                      <td className="px-6 py-3">
                        <button
                          className="hover:text-sky-700"
                          type="button"
                          onClick={()=>handleClick(val.customerAccountNo)}
                        >
                          View cart
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </PageTemplate>
      {showModal ? (
        <CartModal
        customerAcctNo={customerAcctNo}
          setShowModal={setShowModal}
          setShowSuccess={setShowSuccess}
        />
      ) : null}
      {showSuccess ? (
        <Success
          heading="Purchase successful!"
          details="This Purchase is now successful"
          setShowSuccess={setShowSuccess}
        />
      ) : null}
    </>
  );
};
