import { PageTemplate } from "../PageTemplate";
import { Settings } from "./Settings";

export const MarketerSettings = () => {
  return (
    <>
      <PageTemplate tab="Settings">
        <Settings />
      </PageTemplate>
    </>
  );
};
