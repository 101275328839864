import { TfiClose } from "react-icons/tfi";
import { HiOutlineMenu } from "react-icons/hi";
import { NavbarProps } from "../types/types";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoCartOutline, IoSettingsOutline } from "react-icons/io5";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { GoCalendar } from "react-icons/go";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../pages/Store/productsSlice";


const Navbar = ({ toggleTabNavigation, tabNavigation }: NavbarProps) => {
  const { role } = useAppSelector((store: RootState) => store.auth);
  const dispatch = useDispatch();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(e.target.value));
  };
  return (
    <div className="flex fixed  w-full lg:w-[80%] items-center justify-between bg-white py-4 px-4 lg:px-8">
      <div className="hidden lg:flex items-center relative">
      <input
          type="text"
          className="py-3 px-12 pl-8 outline-none rounded-md bg-secondary100 border text-secondary text-sm border-secondary200 w-full"
          placeholder="Search products..."
          onChange={handleSearchChange}
        />
        <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-secondary" />
      </div>
      <div className="flex lg:hidden items-center justify-between w-full">
        <img src="assets/waygibbLogoRbg.png" alt="logo" />
        <div onClick={toggleTabNavigation} className="text-3xl lg:hidden">
          {!tabNavigation ? <HiOutlineMenu /> : <TfiClose />}
        </div>
      </div>
      <div className="hidden lg:flex items-center">
        <div className="flex items-center outline-none mr-5 py-3 px-4 border text-sm rounded-md shadow-sm">
          <GoCalendar className="w-5 h-5" />
          <select className="pl-2">
            <option selected>This Month</option>
            <option>This Week</option>
            <option>This Year</option>
          </select>
        </div>
        <NavLink to="/settings">
          <IoSettingsOutline className="w-6 h-6 mr-5" />
        </NavLink>
        <IoMdNotificationsOutline className="w-6 h-6 mr-5" />
        {role === "marketer" ? (
          <Link to="/cart">
            <IoCartOutline className="w-6 h-6 mr-5" />
          </Link>
        ) : null}
        <NavLink to="/help">
          <TbMessageCircleQuestion className="w-6 h-6" />
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
