import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getCart } from "../thunk";

interface cart {
  customerName: string;
  items: [
    {
      productId: {
        _id: string;
        name: string;
        outrightPrice: string;
        flexiblePrice: string;
      };
      quantity: number
    }
  ];
  _id: string;
  otp: string;
  totalPrice: number;
  customerAccountNo: string;
}

interface cartState {
  cart: cart[];
  status: string;
  error: string;
}

const initialState: cartState = {
  cart: [],
  status: "",
  error: "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getCart.fulfilled, (state, action: PayloadAction<cart[]>) => {
        state.status = "success";
        state.cart = action.payload;
        state.error = "nil";
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default cartSlice.reducer;
