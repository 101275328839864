import { SuccessModalProps } from "../../types/types";

export const Success = ({ heading, details, setShowSuccess }: SuccessModalProps) => {
  return (
    <>
      <div className="justify-center sm:items-center items-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bottom-0">
        <div className="relative sm:w-auto sm:my-6 sm:mx-auto sm:max-w-lg w-80">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
            <section className="w-72 flex items-center justify-center flex-col gap-2 p-6">
              <img
                className="w-10"
                src="assets/round-check.jpg"
                alt="round-check"
              />
              <h2 className="font-semibold">{heading}</h2>
              <p className="text-xs text-center">
                {details}
              </p>
              <button className="bg-violet-950 rounded text-sm text-white py-2 px-7 my-3 w-full" type="button" onClick={() => setShowSuccess(false)}>
                Back
              </button>
            </section>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
