import React, { useState, useEffect } from "react";
import {
  CiFacebook,
  CiInstagram,
  CiYoutube,
  CiLocationOn,
} from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { FiTwitter, FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [showBackToTop, setShowBackToTop] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer
      id="contact"
      className="container bg-[#070015] pt-8 text-white grid grid-cols-1 px-5 pb-4 mx-auto lg:flex lg:justify-between sm:grid-cols-2 gap-y-10 lg:gap-y-0 lg:px-10"
    >
      <div>
        <img src="assets/WaygibbFooter.jpg" alt="logo" />
      </div>
      <div>
        <h1 className="pb-3 text-lg font-medium text-white">Contact</h1>
        <ul className="space-y-6 text-gray-500 font-medium cursor-pointer">
          <li className="flex items-center">
            <FiPhoneCall className="mr-2" /> (234) 9017603145
          </li>
          <li className="flex items-center">
            <AiOutlineMail className="mr-2 text-primary" /> waygibbs5@gmail.com
          </li>
          <li className="flex items-center">
            <CiLocationOn className="mr-2 text-xl text-primary" /> Lagos, Nigeria
          </li>
          <li className="flex items-center">
            <LuAlarmClock className="mr-2 text-primary" />
            10:00-18:00, Mon - Sat
          </li>
        </ul>
      </div>
      <div>
        <h1 className="pb-3 text-lg font-medium">Company</h1>
        <ul className="space-y-5 text-gray-500 cursor-pointer">
          <li onClick={() => navigate("/about")}>About Us</li>
          <li onClick={() => navigate("/services")}>Services</li>
          <li onClick={() => navigate("/contact")}>Contact</li>
        </ul>
      </div>

      
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8  bg-[#070015] text-white p-3 rounded-full shadow-lg hover:bg-primary-dark transition-all"
        >
          ↑
        </button>
      )}
    </footer>
  );
};

export default Footer;
