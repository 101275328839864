import { useState, FormEvent } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { LogoutModalProps } from "../types/types";
import Modal, { ModalContent } from "./elements/Modal";
import { MdOutlineCancel } from "react-icons/md";
import Button from "./elements/Button";

interface Errors {
  customerAccountNo?: string;
  subject?: string;
  message?: string;
}

const DisputeModal = ({ closeModal }: LogoutModalProps) => {
  const [errors, setErrors] = useState<Errors>({});
  const [inputValue, setInputValue] = useState({
    customerAcctNo: "",
    subject: "",
    message: "",
  });
  const token = sessionStorage.getItem("token");

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    if (inputValue.customerAcctNo.trim() === "") {
      newErrors.customerAccountNo =
        "Please enter the correct customer account number";
      isValid = false;
    }

    if (inputValue.subject.trim() === "") {
      newErrors.subject = "Please enter the subject of the dispute";
      isValid = false;
    }

    if (inputValue.message.trim() === "") {
      newErrors.message = "Please enter message";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCreateDispute = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        customerAccountNo: inputValue.customerAcctNo,
        subject: inputValue.subject,
        message: inputValue.message,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/createDispute`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        closeModal();
        toast.success("Thank you!.We will review and get back to you soon!")
        setInputValue({
          customerAcctNo:"",
          subject:"",
          message:""
        })
      } catch (error: any) {
        toast.error(error.response.data.error || "we are unable to send your message now, please try again later");
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-start lg:justify-end"
    >
      <ModalContent className="mx-3 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <form className="w-[90%] my-8" onSubmit={handleCreateDispute}>
          <div className="flex items-center justify-between mb-4">
            <h1 className="font-semibold">Dispute Message</h1>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">
              Customer account number
            </label>
            <input
              name="customerAcctNo"
              value={inputValue.customerAcctNo}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter your customer account number"
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            />
            {errors.customerAccountNo && (
              <p className="text-red-500 text-xs">{errors.customerAccountNo}</p>
            )}
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Subject</label>
            <input
              name="subject"
              value={inputValue.subject}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter the subject"
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            />
            {errors.subject && (
              <p className="text-red-500 text-xs">{errors.subject}</p>
            )}
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Message</label>
            <textarea
              name="message"
              value={inputValue.message}
              onChange={handleInputChange}
              placeholder="Write here..."
              rows={7}
              cols={10}
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-xs">{errors.message}</p>
            )}
          </div>

          <div className="flex items-center justify-between w-full mt-2">
            <Button
              onClick={closeModal}
              className="border text-gray-500 hover:text-black hover:border-black  py-3 w-[40%]"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-secondary text-white py-3 w-[40%] hover:bg-purple-800"
            >
              Send
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DisputeModal;
