import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { SideBarProps } from "../types/types";
import { RxDashboard } from "react-icons/rx";
import { BiStoreAlt } from "react-icons/bi";
import { MdHistory, MdPayments } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { FaPeopleCarryBox } from "react-icons/fa6";
import { TbLogout2 } from "react-icons/tb";
import Back from "./Back";

const SideBar = ({ tabNavigation, toggleTabNavigation, openModal } : SideBarProps ) => {
  const navigate = useNavigate();
  const image : any = sessionStorage.getItem("image")
  const { role } = useAppSelector((store: RootState) => store.auth);
  const fullName = sessionStorage.getItem("name");
  const userEmail = sessionStorage.getItem("userEmail");



  const tabs = [
    {
      title: (
        <div className="flex items-center">
         <RxDashboard  className="w-6 h-6"/>
          <span className="pl-2">Dashboard</span>
        </div>
      ),
      query: "dashboard",
    },
    {
      title: (
        <div className="flex items-center">
          <BiStoreAlt className="w-6 h-6"/>
          <span className="pl-2">Store</span>
        </div>
      ),
      query: "store",
    },
    {
      title: (
        <div className="flex items-center">
          <BsFillPeopleFill className="w-6 h-6"/>
          <span className="pl-2">{role ===  "marketer" ? "My customers" : "Customers"}</span>
        </div>
      ),
      query: "customers",
    },
    {
      title: (
        <div className="flex items-center">
        {role === "marketer" ? <MdHistory className="w-6 h-6"/> : <FaPeopleCarryBox className="w-6 h-6"/> }   
          <span className="pl-2">{role ===  "marketer" ? "History" : "Marketers"}</span>
        </div>
      ),
      query: role ===  "marketer" ? "history" : "marketers",
    },
    {
      title: (
        <div className="flex items-center">
           {role === "marketer" ? <MdPayments className="w-6 h-6"/> : <MdHistory className="w-6 h-6"/> } 
          
          <span className="pl-2"> {role ===  "marketer" ? "Payment" : "Migrations"}</span>
        </div>
      ),
      query: role ===  "marketer" ? "payment" : "migrations",
    },
    {
      title: (
        <div className="flex items-center">
          <RiCustomerService2Line className="w-6 h-6"/>
          <span className="pl-2">Help Center</span>
        </div>
      ),
      query: role === "marketer" ? "help" : "help-center",
    },
    {
      title: (
        <div className="flex items-center">
          <IoSettingsOutline className="w-6 h-6"/>
          <span className="pl-2">Settings</span>
        </div>
      ),
      query: "settings",
    },
    
  ];

  const CurrentTab = useMemo(() => {
    return window.location.pathname.replace("/", "") || "dashboard";
  }, []);

  const handleOverlayClick = () => {
    toggleTabNavigation();
  };

  const handleTabChange = (tab: String) => {
    navigate(`/${tab}`);
    toggleTabNavigation();
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black  opacity-50 z-40 ${
          tabNavigation ? "block" : "hidden"
        }`}
        onClick={handleOverlayClick}
      ></div>
      <ul
        className={`w-[70%] lg:w-[20%] shadow-md fixed bg-white p-4 h-full ${
          !tabNavigation
            ? "hidden lg:block"
            : "flex flex-col sm:items-center justify-between z-50 bg-white text-black h-full animate-fadeIn"
        }`}
      >
        <div className="w-full mb-auto flex flex-col justify-between h-full">
          <div>
            <div className="flex items-center justify-between mb-[3rem] p-2  ">
              <img src="assets/waygibbLogoRbg.png" alt="logo" />
              <Back />
            </div>
            {tabs.map((tab) => (
              <div
                key={tab.query}
                className="pb-4"
                onClick={() => {
                  handleTabChange(tab.query);
                }}
              >
                <li
                  className={`text-sm cursor-pointer ${
                    tab.query === CurrentTab
                      ? "border border-secondary200 bg-secondary100 text-secondary w-full py-3 rounded-md"
                      : ""
                  } p-2`}
                >
                  {tab.title}
                </li>
              </div>
            ))}
          </div>
          <div
            className="mt-auto flex flex-col mb-4"
          >
            <div onClick={openModal} className="bg-[#ECE9F1] flex items-center mb-4 rounded-md text-sm p-3 cursor-pointer border-2 ">
            <TbLogout2 className="w-6 h-6"/>
            <span className="pl-4">Log Out</span>
            </div>
            <div className="flex items-start flex-col xl:flex-row">
              <img src={image} alt="person" className="rounded-[50%] w-12 h-12 mr-3" />
              <div>
                <h1 className="font-semibold capitalize">{fullName}</h1>
                <p className="text-gray-600 text-sm">{userEmail}</p>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </>
  );
};

export default SideBar;
