import Modal, { ModalContent } from "./elements/Modal";
import Button from "./elements/Button";
import { SuccessProps } from "../types/types";

const SuccessModal = ({ closeModal, customerAcctNo }: SuccessProps) => {
  return (
    <Modal open={true} onClose={closeModal} className="flex items-center justify-center">
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <img src="assets/check.png" alt="check" />
        <h1 className="font-semibold text-center pt-6 text-lg">
          Submitted Successfully!
        </h1>
        <p className="text-gray-500 py-3 text-center text-sm w-[90%]">
          The new account number is{" "}
          <span className="font-semibold text-black underline">{customerAcctNo}</span>
        </p>
        <Button
          onClick={closeModal}
          className="bg-secondary w-full mt-2 text-white py-3 px-8 hover:bg-purple-800"
        >
          Back
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
