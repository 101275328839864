import Header from "../../components/header/Header";
import Testimonial from "../../components/Testimonial";
import Footer from "../../components/footer/Footer";
import LogoSlider from "../../components/LogoSlider";
import ServicesCards from "../../components/ServicesCards";
import InformationCard from "../../components/InformationCard";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div className=" bg-secondary100 ">
      <div className=" bg-grey flex justify-center py-4 ">
        <Header />
      </div>
      <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex items-center pt-[4rem] pb-[3rem] lg:pt-[6rem] lg:pb-[5rem] justify-center w-full custom-bg-img  rounded-lg overflow-hidden bg-cover bg-no-repeat bg-center py-10">
        <div className="w-[80%] lg:w-[60%]">
          <div className="text-black font-semibold text-[32px] leading-9 lg:leading-10 lg:text-5xl text-center">
            You may easily own everything.
          </div>
          <div className="text-black font-normal text-sm lg:text-base text-center py-5">
            "We make it simple and reasonably priced to own the newest electronics (home appliances) at Waygibb. Contact one of our marketers to select your gadget, pay in daily payments, and begin using it immediately—no lengthy delays, just immediate access to technology!"
          </div>
          <div className="flex justify-center">
            <button onClick={() => navigate("/about")} className="px-3 py-2 rounded-md bg-secondary text-white mr-2">
              Who We Are
            </button>
            <button onClick={() => navigate("/contact")} className="px-3 py-2 rounded-md bg-white text-primaryButton ">
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center ">
        <div className="flex z-40 w-full justify-center" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500">
          <div className="w-[90%] overflow-hidden bg-cover bg-no-repeat bg-center">
            <img
              src="/assets/waygibhero.jpg"
              className="w-full h-[1000px]  rounded-xl"
              alt="hero"
            />
          </div>

        </div>
        <ServicesCards />
      </div>
      <div className="flex flex-col bg-white items-center justify-start pb-[5rem] px-4 md:px-6">
        <div className="w-[95%] lg:w-[85%]">
          <div className="flex justify-center">
            <div className="font-bold text-[16px] text-black mt-12 mb-[5rem] ">
              How it works
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row items-center  justify-between my-10">
            <div className="w-full lg:w-[50%]">
              <h1 className="font-bold text-[24px] lg:text-[40px] text-black  mt-5">
                Get Registered by a Marketer

              </h1>
              <p className="w-[70%] font-normal text-[14px] text-details">
                "Start your journey with Waygibb by registering through one of our friendly marketers. They’ll guide you through the process, ensuring you’re set up quickly and easily to access the Electrionics you desire."
              </p>

            </div>
            <div data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="h-[400px] w-full lg:w-[47%]">
              <div className="flex lg:items-end lg:justify-end">
                <img
                  src="/assets/phone-work.png"
                  className="h-[400px] w-full lg:w-[80%]"
                  alt="phone"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-between py-10">
            <div data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="h-[400px] w-full lg:w-[52%]">
              <img
                src="/assets/card-work.png"
                className="h-[400px] w-full lg:w-[80%]"
                alt="phone"
              />
            </div>
            <div className="w-full lg:w-[37%]">
              <h1 className="font-bold text-[24px] lg:text-[40px] text-black  mt-5">
                Choose The Electronics
              </h1>
              <p className="w-[70%] font-normal text-[16px] ">

                Explore our extensive range of the latest home appliances. Whether you're looking for a new TV, refrigerator, or washing machine, you'll find the perfect appliance to meet your needs and lifestyle preferences.
              </p>

            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row items-center justify-between py-10">
            <div className="w-full lg:w-[50%]">
              <h1 className="font-bold text-[24px] lg:text-[40px] text-black  mt-5">
                Choose a Payment Plan
              </h1>
              <p className="w-[70%] font-normal text-[14px] text-details">
                Select a payment plan that suits your budget. At Waygibb, we offer flexible daily, weekly, or monthly payment options, allowing you to enjoy your electronics and home appliances while managing your finances comfortably.
              </p>

            </div>
            <div data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="h-[400px] w-full lg:w-[47%]">
              <div className="flex lg:items-end lg:justify-end">
                <img
                  src="/assets/money-work.png"
                  className="h-[400px] w-full lg:w-[80%]"
                  alt="phone"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      <Testimonial />
      <InformationCard />
      <Footer />
    </div>
  );
};

export default LandingPage;
