import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { fetchProducts } from "../thunk"
import { products } from "../../types/types";

interface ProductsState {
  products: products[];
  status: string;
  error: string;
}

const initialState: ProductsState = {
  products: [],
  status: 'idle',
  error: ''
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<products[]>) => {
        state.status = "success"
        state.products = action.payload;
        state.error = 'nil'
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default productsSlice.reducer
