import { useState } from "react";
import SideBar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Navbar2 from "../components/Navbar2";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import LogoutModal from "../components/LogoutModal";
import DisputeModal from "../components/DisputeModal";
import Help from "../components/Help";
import { HelpCenterPage } from "./Admin/HelpCenter/HelpCenterPage";
import ChatAdminCard from "../components/ChatAdminCard";

const HelpCenter = () => {
  const [tabNavigation, setTabNavigation] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const { role } = useAppSelector((store: RootState) => store.auth);
  const [modals, setModals] = useState({
    logout: false,
    dispute: false,
  });

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return (
    <>
      {role === "marketer" ? (
        <div className="flex">
          <SideBar
            tabNavigation={tabNavigation}
            toggleTabNavigation={toggleTabNavigation}
            openModal={() => openModal("logout")}
          />
          <div className="container ml-0 lg:ml-[20%] ">
            <Navbar
              toggleTabNavigation={toggleTabNavigation}
              tabNavigation={tabNavigation}
            />
            <Navbar2 tab="Help Center" openModal={() => openModal("dispute")} />
            <div className="bg-[#F6F4F8] py-1 px-4 lg:px-8 pb-8 h-[90vh]">
              {modals.logout && (
                <LogoutModal closeModal={() => closeModal("logout")} />
              )}
              {modals.dispute && (
                <DisputeModal closeModal={() => closeModal("dispute")} />
              )}

              {showChat && <Help setShowChat={setShowChat}/>}
              {!showChat && (
                <div
                  className="flex items-center justify-center mt-8 lg:mt-18 h-[50vh]"
                  onClick={() => setShowChat(true)}
                >
                  {" "}
                  <ChatAdminCard />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <HelpCenterPage />
      )}
    </>
  );
};

export default HelpCenter;
