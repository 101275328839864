import { useState } from "react";
import SideBar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Navbar2 from "../components/Navbar2";
import LogoutModal from "../components/LogoutModal";
import DisputeModal from "../components/DisputeModal";
import { PageTemplateProps } from "../types/types";
import { AddProductModal } from "./Admin/AddProductModal";
import { Success } from "../components/Store/Success";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

export const PageTemplate = ({ tab, children }: PageTemplateProps) => {
  const { role } = useAppSelector((store: RootState) => store.auth);
  const displayModal = (role: string | null) => {
    switch (role) {
      case "admin":
        return "addProduct";
      default:
        return "dispute";
    }
  };

  const [tabNavigation, setTabNavigation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [modals, setModals] = useState({
    logout: false,
    dispute: false,
    addProduct: false
  });

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return (
    <div className="flex">
      <SideBar
        tabNavigation={tabNavigation}
        toggleTabNavigation={toggleTabNavigation}
        openModal={() => openModal("logout")}
      />
      <div className="w-full ml-0 lg:ml-[20%] ">
        <Navbar
          tabNavigation={tabNavigation}
          toggleTabNavigation={toggleTabNavigation}
        />
        <Navbar2 tab={tab} openModal={() => openModal(displayModal(role))} />
        {children}
        {modals.logout && (
          <LogoutModal closeModal={() => closeModal("logout")} />
        )}
        {modals.dispute && (
          <DisputeModal closeModal={() => closeModal("dispute")} />
        )}
        {modals.addProduct && (
          <AddProductModal
            setShowSuccess={setShowSuccess}
            openModal={() => openModal("addProduct")}
            closeModal={() => closeModal("addProduct")}
          />
        )}
        {showSuccess ? (
          <Success heading="Product added!" details="" setShowSuccess={setShowSuccess}/>
        ) : null}
      </div>
    </div>
  );
};
