import React from 'react'
import { CiShoppingBasket } from "react-icons/ci";
import { LuArrowUpDown } from "react-icons/lu";
import { LuBadgeDollarSign } from "react-icons/lu";
import { MdVoiceChat } from "react-icons/md";



const ServicesCards = () => {
  return (
    <div className="w-[95%] lg:w-[80%]">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 my-[3rem] lg:my-[5rem]">
        <div data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex flex-col rounded-md shadow-md h-[280px] items-center justify-center px-7  bg-white">
          <div className="bg-gray-200 w-10 h-10 rounded-[50%] flex justify-center items-center">
            <LuArrowUpDown className="w-6 h-6" />
          </div>
          <div className="text-center text-[20px] font-bold py-2">
            Easy Access to the Latest Tech
          </div>
          <div className="text-center text-[16px] py-1">

          Without having to worry about upfront expenses, stay current with the newest electronics(Home Appliances). At Waygibb, we provide instant access to high-end devices so you may take advantage of the best technology while making affordable payments on your own time.
          </div>
        </div>
        <div data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex flex-col rounded-md shadow-md h-[280px] items-center justify-center px-7  bg-white">
          <div className="bg-gray-200 w-10 h-10 rounded-[50%] flex justify-center items-center">
            <CiShoppingBasket className="w-6 h-6" />
          </div>
          <div className="text-center text-[20px] font-bold py-2">
            Flexible Daily Payment Plans
          </div>
          <div className="text-center text-[16px] py-1">

            Managing your finances just got easier with Waygibb’s daily payment options. Break down your payments into manageable daily installments, so you can get the Electronics you want without the stress of a heavy, one-time expense.
          </div>
        </div>
        <div data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex flex-col rounded-md shadow-md h-[280px]  items-center justify-center px-7  bg-white">
          <div className="bg-gray-200 w-10 h-10 rounded-[50%] flex justify-center items-center">
            <LuBadgeDollarSign className="w-6 h-6" />
          </div>
          <div className="text-center text-[20px] font-bold py-2 ">
            Simple Approval, No Credit Hassles
          </div>
          <div className="text-center text-[16px] py-1">

            "Forget complicated credit checks and long application processes. Waygibb’s approval is quick and hassle-free, so you can get your Electronics(Home Appliances) without lengthy paperwork or traditional credit barriers—making tech more accessible to everyone."
          </div>
        </div>
        <div data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex flex-col rounded-md shadow-md h-[280px] items-center justify-center px-7 bg-white">
          <div className="bg-gray-200 w-10 h-10 rounded-[50%] flex justify-center items-center">
            <MdVoiceChat className="w-6 h-6" />
          </div>
          <div className="text-center text-[20px] font-bold py-2">
            Immediate Ownership with Long-Term Savings
          </div>
          <div className="text-center text-[16px] py-1">

            "Enjoy your Appliance immediately and pay over time, all while saving more upfront. Waygibb’s plan gives you peace of mind by reducing large initial costs, letting you keep cash on hand for what matters most."
          </div>
        </div>
      </div>
    </div>)
}

export default ServicesCards