import { ProductsModalProps } from "../types/types";
import Modal, { ModalContent } from "./elements/Modal";
import { MdOutlineCancel } from "react-icons/md";
import Button from "./elements/Button";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import store, { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { fetchProducts } from "../redux/thunk";

const ProductsModal = ({ closeModal, productsObj }: ProductsModalProps) => {
  const { products } = useAppSelector((store: RootState) => store.products)
  const dispatch = useAppDispatch()
  const [productData, setProductData] = useState([]);

  const totalAmount = productsObj.orders.reduce(
    (acc: number, order: any) => acc + order.totalPrice,
    0
  );

  useEffect(() => {
    if (productData.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, productData.length]);


  const findProduct = (id: string) => {
    const product = products.find((p) => p._id === id);
    return product
      ? {
          img: product.imageUrl,
          name: product.name,
          brand: product.brand
        }
      : { 
        img: "",
        name: "",
        brand: ""
       };
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 rounded-md shadow-lg overflow-scroll flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <form className="w-[90%] my-8">
          <div className="flex items-center justify-between mb-4">
            <h1 className="font-semibold">List of products</h1>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <div className="flex items-center justify-between flex-row bg-gray-100 p-4 my-8 rounded-md shadow-sm border">
            <div>
              <p className="text-xs text-gray-500 pb-2">Name</p>
              <h1 className="font-semibold text-sm ">{productsObj.fullName}</h1>
            </div>
            <div>
              <p className="text-xs text-gray-500 pb-2">Account Number</p>
              <h1 className="font-semibold text-sm ">
                {productsObj.customerAccountNo}
              </h1>
            </div>
          </div>
          {productsObj.orders[0].items.map((products: any, index: any) => (
            <div className="flex items-center justify-between border shadow-sm p-4 rounded-md mt-4" key={index}>
              <div className="flex items-center">
              <div className="w-14 mr-2">
                <img src={findProduct(products.productId).img} alt="check" className="mr-2" />
                </div>
                <div>
                  <h1 className="font-semibold text-sm ">
                    {findProduct(products.productId).name}
                  </h1>
                  <p className="text-xs text-gray-500 pb-2">{findProduct(products.productId).brand}</p>
                </div>
              </div>
              <div className="flex items-center w-fit border rounded-lg">
                      <span className="w-7 text-center text-xs">
                        {products.quantity}
                      </span>
                    </div>
            </div>
          ))}
          {/* <div className="flex items-center justify-between border shadow-sm p-4 rounded-md mt-4">
            <div className="flex items-center">
              <img src="assets/phone.png" alt="check" className="mr-2" />
              <div>
                <h1 className="font-semibold text-sm ">
                  Apple Iphone 13 pro max
                </h1>
                <p className="text-xs text-gray-500 pb-2">Apple Iphone 13</p>
              </div>
            </div>
            <div className="flex items-center justify-around border rounded-md shadow-sm">
              <span className="border-r-[1px] px-2 py-1 cursor-pointer">-</span>
              <span className="border-r-[1px] px-2 py-1">1</span>
              <span className="px-2 py-1 cursor-pointer">+</span>
            </div>
          </div>
          <div className="flex items-center justify-between border shadow-sm p-4 rounded-md mt-4">
            <div className="flex items-center">
              <img src="assets/phone.png" alt="check" className="mr-2" />
              <div>
                <h1 className="font-semibold text-sm ">
                  Apple Iphone 13 pro max
                </h1>
                <p className="text-xs text-gray-500 pb-2">Apple Iphone 13</p>
              </div>
            </div>
            <div className="flex items-center justify-around border rounded-md shadow-sm">
              <span className="border-r-[1px] px-2 py-1 cursor-pointer">-</span>
              <span className="border-r-[1px] px-2 py-1">1</span>
              <span className="px-2 py-1 cursor-pointer">+</span>
            </div>
          </div>
          <div className="flex items-center justify-between flex-row bg-gray-100 p-4 my-8 rounded-md shadow-sm border">
            <h1>Total</h1>
            <h1> ₦{totalAmount.toLocaleString("en-US")}</h1>
          </div> */}
          <Button
            onClick={closeModal}
            className="bg-secondary w-full text-white py-3 px-8 hover:bg-purple-900 mt-8"
          >
            Back
          </Button>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ProductsModal;
