import { useState, FormEvent, useEffect, Dispatch } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import Button from "./elements/Button";
import LoadingSpinner from "./elements/LoadingSpinner";

interface Errors {
  subject?: string;
  message?: string;
}

interface props {
  setShowChat: Dispatch<React.SetStateAction<boolean>>;
}

const Help = ({ setShowChat }: props) => {
  const { token, uid } = useAppSelector((store: RootState) => store.auth);
  const image: any = sessionStorage.getItem("image");
  const [errors, setErrors] = useState<Errors>({});
  const [isResolved, setIsResolved] = useState("");
  const [subject, setSubject] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    subject: "",
    message: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    getMessagesHandler();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    // if (inputValue.subject.trim() === "") {
    //   newErrors.subject = "Please enter the subject of the dispute";
    //   isValid = false;
    // }

    if (inputValue.message.trim() === "") {
      newErrors.message = "Please enter message";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const deleteMessagesHandler = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/closeMarketerMessages`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("This chat has been closed");
      setShowChat(false);
    } catch (error: any) {
      toast.success("Unable to close chat now , please try again later");
    }
  };

  const getMessagesHandler = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getMessagesByMarketer`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading(false);
      setSubject(response.data.subject);
      setIsResolved(response.data.isResolved);
      setMessages(response.data.chat);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleCreateHelpCenter = async (e: FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    if (validateForm()) {
      let payload;
      if (isResolved) {
        payload = {
          text: inputValue.message,
        };
      } else {
        payload = {
          subject: inputValue.subject,
          text: inputValue.message,
        };
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/helpMessage`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        getMessagesHandler();
        // toast.success("Thank you!.We will review and get back to you soon!");
        setInputValue({
          subject: "",
          message: "",
        });
      } catch (error: any) {
        // toast.error(
        //   "we are unable to send your message now, please try again later"
        // );
      }
    }
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="flex items-end justify-end mt-8">
            <p
              onClick={deleteMessagesHandler}
              className=" flex items-center text-red-500 bg-white px-6 py-4 cursor-pointer hover:bg-red-100 rounded-xl text-center font-bold text-sm"
            >
              <MdOutlineDelete className="mr-2 w-5 h-5" /> close chat
            </p>
          </div>
          <div className="flex items-center justify-center mt-8">
            <p className="text-gray-800 bg-white p-4 w-[70vh] text-center self-center font-bold text-lg">
              {subject}
            </p>
          </div>
        </>
      )}
      {isLoading ? (
        <div className="mt-12">
          <LoadingSpinner />
        </div>
      ) : (
        messages.map((message: any) => (
          <section
            className={`flex ${
              message.isMarketer
                ? " items-end justify-end"
                : "items-start justify-start"
            }  w-full mt-4 lg:mt-14`}
          >
            {!message.isMarketer && (
              <img
                src={image}
                alt=""
                className="w-12 h-12 mr-3 rounded-[50%] border border-secondary"
              />
            )}
            <div
              className={`flex flex-col ${
                message.isMarketer ? "bg-secondary text-white" : "bg-white"
              }  rounded-md shadow-md p-3 max-w-[60%]`}
            >
              <div className="flex items-start">
                <p className="text-sm">{message.text}</p>
              </div>
            </div>
            {message.isMarketer && (
              <img
                src={image}
                alt=""
                className="w-12 h-12 ml-3 rounded-[50%] border border-secondary"
              />
            )}
          </section>
        ))
      )}

      <main className="flex items-center justify-center mt-8 lg:mt-12 ">
        <section className="flex items-center justify-center bg-white rounded-md shadow-sm w-full ">
          <form className="w-[90%] my-8" onSubmit={handleCreateHelpCenter}>
            {isResolved && (
              <div className="flex flex-col mb-6">
                <label className="text-sm font-medium pb-1">Subject</label>
                <input
                  name="subject"
                  value={inputValue.subject}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter the subject"
                  className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
                />
                {errors.subject && (
                  <p className="text-red-500 text-xs">{errors.subject}</p>
                )}
              </div>
            )}

            <div className="flex flex-col mb-6">
              <label className="text-sm font-medium pb-1">Message</label>
              <textarea
                name="message"
                value={inputValue.message}
                onChange={handleInputChange}
                placeholder="Write here..."
                rows={5}
                cols={10}
                className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-xs">{errors.message}</p>
              )}
            </div>

            <div className="flex items-center justify-between w-full mt-2">
              <Button
                onClick={goBack}
                className="border text-gray-500 hover:text-black hover:border-black  py-3 w-[40%]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bg-secondary text-white py-3 w-[40%] hover:bg-purple-800"
              >
                Send
              </Button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default Help;
