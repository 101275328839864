import { useState, useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import axios from "axios";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";

const AdminDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [stats, setStats] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAdminDashboardStat`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          Total Customers
        </h1>
        <h1 className="font-semibold text-3xl">
          {isLoading ? "0" : stats?.totalCustomers?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalCustomers}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          Daily Expected Amount
        </h1>
        <h1 className="font-semibold text-3xl">
          {" "}
          ₦{isLoading
            ? "0.00"
            : stats?.totalExpectedDailyAmount?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalExpectedDailyAmount}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-[13px] font-medium pb-4 text-gray-700">
          Total Outright sales for the month
        </h1>
        <h1 className="font-semibold text-3xl">
        ₦ {isLoading
            ? "0.00"
            : stats?.totalOutrightRevenue?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalOutrightRevenue}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          {" "}
          Total disputers
        </h1>
        <h1 className="font-semibold text-3xl">
          {isLoading ? "0" : stats?.totalDisputes?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalDisputes}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          {" "}
          Total loan for the month
        </h1>
        <h1 className="font-semibold text-3xl">
        ₦{isLoading
            ? "0.00"
            : stats?.totalLoanRevenue?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalLoanRevenue}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          {" "}
          Total loan repayment left
        </h1>
        <h1 className="font-semibold text-3xl">
          {" "}
          ₦{isLoading
            ? "0.00"
            : stats?.totalRepaymentAmount?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalRepaymentAmount}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
      <div
        className={`bg-white p-5 rounded-md shadow-md w-[100%] ${
          isLoading && "animate-pulse"
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          {" "}
          Total loan paid
        </h1>
        <h1 className="font-semibold text-3xl">
        ₦{isLoading
            ? "0.00"
            : stats?.totalLoanPaidAmount?.toLocaleString("en-US")}
        </h1>
        <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong />  {stats.lastMonthtotalLoanPaidAmount}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDetails;
