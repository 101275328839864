import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "./redux/hooks";
import { logout } from "./redux/auth/authSlice";
import { RootState } from "./redux/store";
import { Toaster } from "react-hot-toast";
import Login from "./pages/Authentication/Login";
import Signup from "./pages/Authentication/Signup";
import Dashboard from "./pages/Dashboards/Dashboard";
import { MarketerStore } from "./pages/Store/MarketerStore";
import { MarketerHistory } from "./pages/History/MarketerHistory";
import { MarketerPayment } from "./pages/Payment/MarketerPayment";
import { MarketerSettings } from "./pages/Settings/MarketerSettings";
import HelpCenter from "./pages/HelpCenter";
import { Cart } from "./pages/Cart/Cart";
import MyCustomers from "./pages/MyCustomers/MyCustomers";
import AdminSignup from "./pages/Authentication/Admin/AdminSignup";
import { MarketersPage } from "./pages/Admin/Marketers/MarketersPage";
import { MigrationPage } from "./pages/Admin/Migration/MigrationPage";
import { HelpCenterPage } from "./pages/Admin/HelpCenter/HelpCenterPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import AboutUS from "./pages/About/AboutUS";
import Services from "./pages/Services/Services";
import Contact from "./pages/contact/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import { ProductsList } from "./pages/OurProducts/ProductsList";


function App() {
  const dispatch = useAppDispatch();
  const { token, isLoggedIn } = useAppSelector(
    (store: RootState) => store.auth
  );
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    AOS.refresh();
  });


  useEffect(() => {
    if (token) {
      const expirationTime = sessionStorage.getItem("expirationTime");
      const currentTime = Date.now();
      if (expirationTime && currentTime < Number(expirationTime)) {
        setIsTokenValid(true);
      } else {
        dispatch(logout());
      }
    } else {
      setIsTokenValid(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && isLoggedIn) {
      const expirationTime = Date.now() + 60 * 60 * 1000;
      sessionStorage.setItem("expirationTime", expirationTime.toString());
    }
  }, [token, isTokenValid]);

  return (
    <>
      <Toaster position="top-right" />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/admin-signup" element={<AdminSignup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/store" element={<MarketerStore />} />
          <Route path="/history" element={<MarketerHistory />} />
          <Route path="/payment" element={<MarketerPayment />} />
          <Route path="/settings" element={<MarketerSettings />} />
          <Route path="/marketers" element={<MarketersPage />} />
          <Route path="/migrations" element={<MigrationPage />} />
          <Route path="/help-center" element={<HelpCenterPage />} />
          <Route path="/help" element={<HelpCenter />} />
          <Route path="/customers" element={<MyCustomers />} />
          <Route path="/product" element={<ProductsList/>}/>
         </Routes>
      </Router>
    </>
  );
}

export default App;
