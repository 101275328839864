import { GoCalendar } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { getAllCustomers, getPaymentEvents, payDaily } from "../../redux/thunk";
import { Success } from "../../components/Store/Success";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { RootState } from "../../redux/store";
import { PaymentProps } from "../../types/types";
import { Preloader } from "../../components/elements/Preloader";
import toast from "react-hot-toast";

const Payment = ({ openModal, setCustomerId }: PaymentProps) => {
  const [dailyAmount, setDailyAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { event } = useAppSelector((store: RootState) => store.event);
  const { customers, status } = useAppSelector(
    (store: RootState) => store.customers
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (event.length === 0) {
      dispatch(getPaymentEvents());
    }
  }, [dispatch, event.length]);

  const getDate = () => {
    const today = new Date();
    const dataTime = `${today.getDate().toString().padStart(2, "0")}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${today.getFullYear()}`;
    return dataTime;
  };

  const handleModal = (customer: any) => {
    setDailyAmount(customer.dailyAmount);
    setShowPaymentModal((prev) => !prev);
  };

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getAllCustomers());
    }
  }, [customers.length, dispatch]);

  const findCustomer = (id: string) => {
    const customer = customers.find((c) => c._id === id);
    return customer
      ? {
          fullName: customer.fullName,
          customerAccountNo: customer.customerAccountNo,
        }
      : { fullName: "", customerAccountNo: "" };
  };

  interface dataType {
    id: string;
    amountPaid: number;
  }
  const handlePayment = ({ id, amountPaid }: dataType) => {
    setIsLoading(true);
    dispatch(payDaily({ eventId: id, amountPaid: amountPaid })).then(() => {
      setShowSuccess(true);
      setShowPaymentModal(false);
      setIsLoading(false)
      dispatch(getPaymentEvents());
    })
    .catch((error) => {
      console.error("An error occurred while making payment:", error);
      toast.error("Payment failed!")
    });

  };

  const sortedEvents = [...event].sort(
    (a, b) => new Date(b.startDay).getTime() - new Date(a.startDay).getTime()
  );

  return (
    <main className="overflow-x-auto">
      <div className="bg-white rounded-md shadow-md p-5 w-full sm:w-1/2">
        <div className="flex items-center justify-between pb-4">
          <h1 className="text-base font-semibold ">Today's Payment</h1>
          <div className="flex items-center border text-sm border-secondary200 py-2 sm:px-5 px-2 rounded-md shadow-sm">
            <p>{getDate()}</p>
          </div>
        </div>
        {status === "loading" ? (
          <div className="flex justify-between h-20">
            <LoadingSpinner />
          </div>
        ) : (
          sortedEvents.map((d) => (
            <section key={nanoid()}>
              <div className="flex items-center justify-between py-2">
                <div onClick={() => handleModal(d)}>
                  <h1 className="font-semibold text-sm sm:text-base">
                    {findCustomer(d.customerId).fullName}
                  </h1>
                  <p className="text-gray-700 text-xs sm:text-sm">
                    {findCustomer(d.customerId).customerAccountNo}
                  </p>
                </div>
                <span onClick={setCustomerId(d.customerId)}>
                  <div
                    onClick={openModal}
                    className="flex items-center cursor-pointer bg-secondary100 text-secondary border-secondary200 outline-none py-2 sm:px-4 px-2 border text-xs sm:text-sm rounded-md shadow-sm"
                  >
                    <GoCalendar className="w-4 h-4" />
                    <p className="pl-2">View dates</p>
                  </div>
                </span>
              </div>
              {showPaymentModal && (
                <>
                  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto overflow-y-auto outline-none focus:outline-none">
                    <div className="relative sm:w-auto sm:my-6 sm:mx-auto sm:max-w-lg w-80 h-5/6 overflow-y-auto scroll-bar">
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2">
                        <div>
                          <label
                            className="block font-semibold text-xs mb-2"
                            htmlFor="payment-amt"
                          >
                            Input amount
                          </label>
                          <input
                            className="bg-gray-100 text-xs p-2 w-full"
                            type="text"
                            name="payment amount"
                            id="payment-amt"
                            value={dailyAmount}
                            onChange={(e: any) =>
                              setDailyAmount(e.target.value)
                            }
                          />
                        </div>
                        <div className="flex gap-2 mt-2">
                          <button
                            className="border w-40 p-2 rounded border-gray-300 text-xs bg-gray-100 text-gray-500"
                            type="button"
                            onClick={handleModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="border w-40 bg-violet-950 rounded text-white text-xs p-2"
                            type="button"
                            onClick={() =>
                              handlePayment({
                                id: d._id,
                                amountPaid: d.dailyAmount,
                              })
                            }
                          >
                            {isLoading ? <Preloader /> : "Make payment"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              )}
            </section>
          ))
        )}
      </div>
      {showSuccess ? (
        <Success
          heading="Successful purchase!"
          details="Lorem ipsum dolor sit amet consectetur. Maecenas sed neque eget"
          setShowSuccess={setShowSuccess}
        />
      ) : null}
    </main>
  );
};

export default Payment;
