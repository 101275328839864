import { PageTemplate } from "../PageTemplate";
import { Store } from "./Store";

export const MarketerStore = () => {
  return (
    <>
      <PageTemplate tab="Store">
        <Store />
      </PageTemplate>
    </>
  );
};
