import { FormEvent, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Banner from "../../components/Banner";
import { GoEyeClosed, GoEye } from "react-icons/go";
import { useAppDispatch, useAppSelector} from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { login } from "../../redux/thunk";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

interface Errors {
  email?: string;
  password?: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Errors>({});
  const { status } = useAppSelector((store: RootState) => store.auth);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const newValue = name === "email" ? value.toLowerCase() : value;
  
    if (newValue.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
  
    setInputValue((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    if (inputValue.email.trim() === "") {
      newErrors.email = "Please enter a valid email address or username";
      isValid = false;
    }

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters and contain both letters and numbers";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const loginData = {
      email: inputValue.email,
      password: inputValue.password,
    };
    const resultAction =  await dispatch(login(loginData));
    try {
      if (validateForm()) {
       
        if (login.fulfilled.match(resultAction)) {
          navigate("/dashboard");
          toast.success("Login successful!");
        }
        if (login.rejected.match(resultAction)) {
          toast.error("Email Or Password Invalid!");
        }
      }
    } catch (error) {
      toast.error("Email Or Password Invalid!");
    }
  };

  return (
    <div className="flex items-center justify-beteen h-[100vh]">
      <div className="px-4 lg:px-14 w-full lg:w-[45%] mt-10 lg:mt-[10rem]">
        <img src="assets/waygibbLogoRbg.png" alt="logo" className="pb-20" onClick={() => navigate("/")} />
        <form className="px-4 lg:px-14 mb-[5rem]" onSubmit={handleLogin}>
          <h1 className="font-bold text-xl mb-16">Log in to your account</h1>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium pb-1">Email</label>
            <input
              name="email"
              value={inputValue.email}
              onChange={handleInputChange}
              type="text"
              required
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col relative mb-4">
            <label className="text-sm font-medium pb-1">Password</label>
            <input
              name="password"
              value={inputValue.password}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              required
              placeholder="Enter your correct email address"
              className="bg-gray-100 text-sm outline-none p-2 lg:p-3 rounded-md border border-gray-200"
            />
            <figure
              className="absolute right-4 top-[65%] transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <GoEye /> : <GoEyeClosed />}
            </figure>
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password}</p>
            )}
          </div>
          <button
            className="bg-secondary text-white w-[100%] text-base font-semibold p-2 lg:p-3 rounded-md shadow-sm mt-8 lg:mt-12 mb-6 transition-all duration-500 hover:border-secondary hover:border-2 hover:text-secondary hover:bg-transparent"
            type="submit"
          >
         {status === "loading" ? <LoadingSpinner /> : 'Login' }
          </button>
          <p className="text-sm text-center">
            Don’t have an account?{" "}
            <a href="/signup">
              <span className="text-secondary underline">Sign up</span>
            </a>
          </p>
        </form>
      </div>
      <div>
        <Banner />
      </div>
    </div>
  );
};

export default Login;
