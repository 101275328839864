import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import axios from "axios";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import store, { RootState } from "../redux/store";
import toast from "react-hot-toast";
import Modal, { ModalContent } from "./elements/Modal";
import { AddNewCustomerProps } from "../types/types";
import { MdOutlineCancel } from "react-icons/md";
import { getCustomersHandler } from "../redux/thunk";
import Button from "./elements/Button";

interface Errors {
  fullName?: string;
  address?: string;
  phoneNumber?: string;
  // marketer?: string;
}

const AddNewCustomerModal = ({
  closeModal,
  openModal,
  setCustomerAcctNo,
}: AddNewCustomerProps) => {
  const [errors, setErrors] = useState<Errors>({});
  const [marketers, setMarketers] = useState([]);
  const [inputValue, setInputValue] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    marketer: "",
  });
  const dispatch = useAppDispatch()
  const { token, role } = useAppSelector((store: RootState) => store.auth);


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    if (inputValue.fullName.trim() === "") {
      newErrors.fullName = "Please enter full name";
      isValid = false;
    }

    if (inputValue.address.trim() === "") {
      newErrors.address = "Please enter full address";
      isValid = false;
    }

    if (inputValue.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Please enter the correct phone number";
      isValid = false;
    }

    // if (inputValue.marketer.trim() === "") {
    //   newErrors.marketer = "Please assign a marketer to this customer";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const handleCreateCustomer = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (role === "marketer") {
      if (validateForm()) {
        const payload = {
          fullName: inputValue.fullName,
          address: inputValue.address,
          phoneNumber: inputValue.phoneNumber,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/createCustomer`,
            payload,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setCustomerAcctNo(response.data.customerAccountNo);
          closeModal();
          openModal();
          setInputValue({
            fullName: "",
            address: "",
            phoneNumber: "",
            marketer: "",
          });
          dispatch(getCustomersHandler())
        } catch (error: any) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      handleCreateAdminCustomer(e);
    }
  };

  useEffect(() => {
    getMarketersHandler();
  }, []);

  const getMarketersHandler = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllMarketer`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setMarketers(response.data);
    } catch (error: any) {
      setMarketers([]);
    }
  };

  const handleCreateAdminCustomer = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        fullName: inputValue.fullName,
        address: inputValue.address,
        phoneNumber: inputValue.phoneNumber,
        marketerId: inputValue.marketer
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/adminCreateCustomer`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setCustomerAcctNo(response.data.customerAccountNo)
        closeModal();
        openModal();
        setInputValue({
          fullName:"",
          address:"",
          phoneNumber:"",
          marketer: ""
        })
      } catch (error: any) {
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <form className="w-[90%] my-8" onSubmit={handleCreateCustomer}>
          <div className="flex items-center justify-between mb-4">
            <h1 className="font-semibold">Add new customer</h1>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Full name</label>
            <input
              name="fullName"
              value={inputValue.fullName}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter your full name"
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            />
            {errors.fullName && (
              <p className="text-red-500 text-xs">{errors.fullName}</p>
            )}
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Address</label>
            <input
              name="address"
              value={inputValue.address}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter your address"
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            />
            {errors.address && (
              <p className="text-red-500 text-xs">{errors.address}</p>
            )}
          </div>
          <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Phone number</label>
            <input
              name="phoneNumber"
              value={inputValue.phoneNumber}
              onChange={handleInputChange}
              type="text"
              placeholder="+234: Enter your phone number"
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs">{errors.phoneNumber}</p>
            )}
          </div>
          {role === "admin" &&  <div className="flex flex-col mb-6">
            <label className="text-sm font-medium pb-1">Marketer</label>
            <select
              name="marketer"
              value={inputValue.marketer}
              onChange={handleInputChange}
              className="bg-gray-100 p-4 shadow-sm text-sm outline-none rounded-md"
            >
              <option value="" disabled>
                Assign a marketer
              </option>
              {marketers.map((marketer: any) => (
                <option value={marketer?._id} key={marketer?._id}>{marketer.fullName}</option>
              ))}
            </select>

            {/* {errors.marketer && (
              <p className="text-red-500 text-xs">{errors.marketer}</p>
            )} */}
          </div>}
         
          <div className="flex items-center justify-between w-full mt-2">
            <Button
              onClick={closeModal}
              type="submit"
              className="border text-gray-500 hover:text-black hover:border-black  py-3 w-[40%]"
            >
              Cancel
            </Button>
            <Button className="bg-secondary text-white py-3 w-[40%] hover:bg-purple-800">
              Send
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddNewCustomerModal;
