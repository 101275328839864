import { useEffect, useState } from "react";
import { ProductCard } from "./ProductCard";
import { Product } from "./product";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Preloader } from "../../components/elements/Preloader";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/getAllProducts`;

export const ProductsList = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const productsPerPage = 15; 

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const response = await axios.get(API_URL);

                const transformedProducts = response.data.map((item: any) => ({
                    productId: item._id,
                    img: item.imageUrl,
                    name: item.name,
                    description: item.description,
                    outright: parseFloat(item.outrightPrice),
                    flexible: parseFloat(item.flexiblePrice),
                }));

                setProducts(transformedProducts);
                setFilteredProducts(transformedProducts);
                const reversedProducts = transformedProducts.reverse();

                setProducts(reversedProducts);
                setFilteredProducts(reversedProducts);
            } catch (err) {
                setError("Failed to fetch products. Please try again or check your data connection.");
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
        });
    }, []);

    useEffect(() => {
        const results = products.filter((product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(results);
        setCurrentPage(1);
    }, [searchTerm, products]);


    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    if (error) {
        return <div className="text-center py-6 text-red-600">{error}</div>;
    }

    return (
        <>
            <div className="bg-grey flex justify-center py-4">
                <Header />
            </div>

            <div className="text-center my-4 px-4 sm:px-8 md:px-12">
                <h1 className="text-2xl font-bold">Welcome to Our Product Catalog</h1>
                <p className="text-[#070015] mt-2">
                    Browse through our selection of amazing products and find what you love.
                </p>
                <p className="text-gray-600 mt-2">
                    Contact us today to checkout your electronics today!!
                </p>
            </div>

            <div className="text-center my-4 px-4 sm:px-8 md:px-12">
                <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 w-full sm:w-1/2 mx-auto"
                    placeholder="Type the name of the electronic brand"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="flex justify-center">
                <button onClick={() => navigate("/about")} className="px-3 py-2 rounded-md bg-secondary text-white mr-2">
                    Who We Are
                </button>
                <button onClick={() => navigate("/contact")} className="px-3 py-2 rounded-md bg-white text-primaryButton">
                    Contact Us
                </button>
            </div>

            {loading && (
                <div className="text-center py-6">
                    <Preloader />
                </div>
            )}

            <div
                className="container mx-auto p-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-10"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                {currentProducts.length > 0 ? (
                    currentProducts.map((product) => (
                        <ProductCard key={product.productId} product={product} />
                    ))
                ) : (
                    <div className="text-center col-span-full text-gray-500">
                        <img src="assets/nothing.png" alt="No products found" className="mx-auto mb-4 w-[230px] h-[230px]" />
                        No products found.
                    </div>
                )}
            </div>


           
            <div className="flex justify-center items-center my-6">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`px-4 py-2 rounded-l-md ${currentPage === 1
                        ? "bg-[#070015]-200 text-gray-500 cursor-not-allowed"
                        : "bg-[#070015]-300 hover:bg-gray-400 text-black"
                        }`}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span className="px-4 py-2 bg-white  text-[#070015]-700">
                    {`Page ${currentPage} of ${totalPages}`}
                </span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`px-4 py-2 rounded-r-md ${currentPage === totalPages
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : "bg-gray-300 hover:bg-gray-400 text-black"
                        }`}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>


            <Footer />
        </>
    );
};
