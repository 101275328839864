import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { LuArrowUpDown } from "react-icons/lu";
import ServicesCards from '../../components/ServicesCards';
import InformationCard from '../../components/InformationCard';


const Services = () => {
  return (
<div className=" bg-secondary100 ">
      <div className=" bg-grey flex justify-center py-4 ">
        <Header />
      </div>
     
      <div className="flex flex-col items-center justify-center pt-[4rem] lg:pt-[6rem] ">
        <div className=' w-[90%] lg:w-[60%] pb-[2rem] lg:pb-[3rem]' data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500">
          <h1 className='text-base leading-5 font-bold text-center'>Our Services</h1>
          <h1 className="text-[32px] lg:text-[56px] text-center leading-9 lg:leading-10 font-bold py-4 lg:py-6">Our services are <br/> top notch</h1>
          <p className='text-base left-6 text-center '>At WAYGIBB, we offer a range of services to ensure you have the best experience when purchasing gadgets from us. From product selection to after-sales support, we are committed to providing top-notch service every step of the way.</p>
        </div>
       <ServicesCards />
        <div></div>
      </div>
           <InformationCard />
      <Footer />
    </div>  )
}

export default Services