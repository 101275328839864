import { useState, useEffect } from "react";
import { CustomerTableProps } from "../types/types";
import axios from "axios";
import Button from "./elements/Button";
import { IoAddSharp } from "react-icons/io5";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

const CustomersTable = ({
  openCustomerModal,
  openCalender,
  setCustomerId
}: CustomerTableProps) => {
  const { token, uid } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false)
  
  const [customers, setCustomers] = useState([])
  useEffect(() => {
    getCustomersHandler()
  }, [])

  const getCustomersHandler = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/adminGetCustomersByMarketer/${uid}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const sorted = response.data.sort((a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA;
      });
      console.log(sorted)
      setCustomers(sorted);
      setIsLoading(false)
    } catch (error: any) {
      setCustomers([]);
      setIsLoading(false)
    }
  };
  return (
    <main className="overflow-x-auto w-full">
      <div className={`bg-white rounded-md shadow-md pb-6 ${isLoading && "animate-pulse h-[60vh]"}`}>
        <div className="w-full overflow-x-auto">
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">My customers</h1>
            <Button
              onClick={openCustomerModal}
              className="mr-3 flex items-center border text-sm border-black text-black py-2 px-6 cursor-pointer hover:text-gray-700 hover:border-gray-700"
            >
              <IoAddSharp className="mr-2" /> Add new customer
            </Button>
          </div>
          <table className="w-full" style={{ minWidth: "700px" }}>
            <thead className="bg-gray-50">
              <tr>
                <th className="p-4 border-b">S/N</th>
                <th className="p-4 border-b">Fullname</th>
                <th className="p-4 border-b">Phone Number</th>
                <th className="p-4 border-b">Customer Account No</th>
                <th className="p-4 border-b">Address</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer: any, index) => (
                <tr className="text-center border-b-[1px]" key={index}>
                   <td className="p-4 font-semibold text-secondary">
                    {index + 1}
                  </td> 
                  <td className="p-4">
                    {customer?.fullName}
                  </td>
                  <td className="p-4">{customer.phoneNumber}</td>
                  <td className="p-4 ">{customer.customerAccountNo}</td>
                  <td className="p-4 overflow-hidden">{customer.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default CustomersTable;
