import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { FaArrowUpLong,  FaArrowDownLong } from "react-icons/fa6";

const MarketerDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [stats, setStats] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/markerterStat`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
    <div className={`bg-white p-5 rounded-md shadow-md w-[100%] ${isLoading && 'animate-pulse '}`}>
      <h1 className="text-sm font-medium pb-4 text-gray-700">Total Customers</h1>
      <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.totalCustomers}</h1>
      <div className="flex items-center text-xs pt-3">
        <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]"><FaArrowDownLong/>{stats.lastMonthtotalCustomers}% </p>
        <p className="pl-2 text-gray-600">Compared to last month</p>
      </div>
    </div>
    <div className={`bg-white p-5 rounded-md shadow-md w-[100%] ${isLoading && 'animate-pulse '}`}>
      <h1 className="text-sm font-medium pb-4 text-gray-700">Daily Expected Amount</h1>
      <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" : stats.totalPendingDailyAmount}</h1>
      <div className="flex items-center text-xs pt-3">
        <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]"><FaArrowDownLong/>{stats.lastMonthtotalPendingDailyAmount}% </p>
        <p className="pl-2 text-gray-600">Compared to last month</p>
      </div>
    </div>
    <div className={`bg-white p-5 rounded-md shadow-md w-[100%] ${isLoading && 'animate-pulse '}`}>
      <h1 className="text-sm font-medium pb-4 text-gray-700">Total Revenue</h1>
      <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" : stats.totalRevenue}</h1>
      <div className="flex items-center text-xs pt-3">
        <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]"><FaArrowDownLong/>{stats.lastMonthtotalRevenue}% </p>
        <p className="pl-2 text-gray-600">Compared to last month</p>
      </div>
    </div>
    <div className={`bg-white p-5 rounded-md shadow-md w-[100%] ${isLoading && 'animate-pulse '}`}>
      <h1 className="text-sm font-medium pb-4 text-gray-700"> Total Sales</h1>
      <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" : stats.totalCompletedOrders}</h1>
      <div className="flex items-center text-xs pt-3">
        <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]"><FaArrowDownLong/> {stats.lastMonthtotalCompletedOrders}% </p>
        <p className="pl-2 text-gray-600">Compared to last month</p>
      </div>
    </div>
  </div>
  )
}

export default MarketerDetails