import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getPaymentEvents } from "../thunk";

interface eventState {
  event: Event[];
  status: string;
  error: string;
}
interface Event {
  startDay: any;
  _id: string,
  customerId: string,
  dailyAmount: number
}

const initialState: eventState = {
  event: [],
  status: "idle",
  error: "",
};

const helpCenterSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentEvents.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(
        getPaymentEvents.fulfilled,
        (state, action: PayloadAction<{ events: Event[] }>) => {
          state.status = "success";
          state.event = action.payload.events;
          state.error = "nil";
        }
      )
      .addCase(getPaymentEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default helpCenterSlice.reducer;
