import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import LogoSlider from "../../components/LogoSlider";
import InformationCard from "../../components/InformationCard";

const Contact = () => {
  return (
    <div className=" bg-secondary100 ">
      <div className=" bg-grey flex justify-center py-4 ">
        <Header />
      </div>

      <div className="flex flex-col items-center justify-center pt-[4rem] lg:pt-[6rem] ">
        <div className="flex z-40 w-full items-center flex-col justify-center" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500">
          <div className="w-[90%] lg:w-[60%] pb-[3rem] lg:pb-[5rem]">
            <h1 className="text-base leading-5 font-bold text-center">
              Contact us
            </h1>
            <h1 className="text-[32px] lg:text-[56px] leading-9 lg:leading-10 font-bold text-center py-4 lg:py-6">
              We are here to help
            </h1>
            {/* <p className="text-base left-6 text-center ">
              Integer rhoncus eu elit morbi massa vitae. Sit aliquet lacus nunc
              eu enim tortor. Massa egestas etiam quis eget vestibulum mauris
              adipiscing eu sem.
            </p> */}
          </div>
          <div className="lg:border-2 w-full lg:w-[55%] border-secondary bg-white p-8 lg:p-12 rounded-lg lg:shadow-md">
            <form>
              <div className="flex flex-col mb-4">
                <label className="text-base leading-5 pb-2 font-normal">
                  Full Name
                </label>
                <input type="text" name="fullName" className="bg-secondary100 p-4 rounded-md shadow-sm border border-secondary200 outline-none" />
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-base leading-5 pb-2 font-normal">
                  Email
                </label>
                <input type="text" name="email" className="bg-secondary100 p-4 rounded-md shadow-sm border border-secondary200 outline-none" />
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-base leading-5 pb-2 font-normal">
                  Subject
                </label>
                <input type="text" name="subject" className="bg-secondary100 p-4 rounded-md shadow-sm border border-secondary200 outline-none" />
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-base leading-5 pb-2 font-normal">
                  Message
                </label>
                <textarea cols={30} rows={7} name="fullName" className="bg-secondary100 p-4 rounded-md shadow-sm border border-secondary200 outline-none" />
              </div>
             <button className="p-4 rounded-md shadow-md bg-secondary text-white w-full mt-6 hover:bg-purple-900">Submit</button>
            </form>
          </div>
        </div>
        {/* <LogoSlider /> */}
      </div>
<InformationCard />
      <Footer />
    </div>
  );
};

export default Contact;
