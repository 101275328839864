import { GoXCircle } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect, useState } from "react";
import { CartModalProps } from "../types/types";
import { fetchProducts, getCart, purchaseOrder } from "../redux/thunk";
import { nanoid } from "@reduxjs/toolkit";
import { Preloader } from "./elements/Preloader";
import toast from "react-hot-toast";

export const CartModal = ({
  customerAcctNo,
  setShowModal,
  setShowSuccess,
}: CartModalProps) => {
  const [inputFilled, setInputFilled] = useState(false);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const [otp, setOtp] = useState("");

  const { cart } = useAppSelector((store) => store.cart);
  const { products } = useAppSelector((store) => store.products);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setShowModal(false);
  };

  const filteredCart = cart.filter(
    (cart) => customerAcctNo === cart.customerAccountNo
  );

  const handlePurchase = () => {
    if (!otp) {
      setInputFilled(true);
      return;
    }
    setIsLoading(true)
    const data = {
      customerAccountNo: filteredCart[0].customerAccountNo,
      otp: otp,
    };
    dispatch(purchaseOrder(data)).then(() => {
      setShowSuccess(true);
      setShowModal(false);
      setIsLoading(false)
      dispatch(getCart());
    })
    .catch((error) => {
      console.error("An error occurred while making purchase:", error);
      toast.error("Purchase failed!")
    });
};

  useEffect(() => {
    if (productData.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, productData.length]);

  const findProduct = (id: string) => {
    const product = products.find((p) => p._id === id);
    return product
      ? {
          img: product.imageUrl,
          name: product.name,
          brand: product.brand,
        }
      : {
          img: "",
          name: "",
          brand: "",
        };
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto overflow-y-auto outline-none focus:outline-none">
        <div className="relative sm:w-auto sm:my-6 sm:mx-auto sm:max-w-lg w-80 h-5/6 overflow-y-auto scroll-bar">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between px-5 pt-5 rounded-t">
              <h2 className="font-bold">Cart</h2>
              <GoXCircle onClick={handleClose} />
            </div>

            <section className="relative px-6 py-2">
              <section>
                <div className="flex p-2 sm:flex-row flex-col justify-between mb-2 rounded-lg bg-gray-100">
                  <span className="mb-1">
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="username"
                    >
                      Name
                    </label>
                    <input
                      className="w-32 text-sm font-semibold"
                      type="text"
                      name="username"
                      id="username"
                      value={filteredCart[0].customerName}
                      disabled
                    />
                  </span>
                  <span>
                    <label
                      className="block text-gray-500 text-xs"
                      htmlFor="acct-no"
                    >
                      Account number
                    </label>
                    <input
                      className="w-32 text-sm font-semibold"
                      type="text"
                      name="acct-no"
                      id="acct-no"
                      value={filteredCart[0].customerAccountNo}
                      disabled
                    />
                  </span>
                </div>
                {filteredCart[0].items.map((item) => (
                  <div
                    key={nanoid()}
                    className="sm:flex rounded-lg border items-center p-2 mb-2 w-full"
                  >
                    <div className="flex">
                      <div className="w-14 mr-2">
                        <img
                          className="rounded-lg"
                          src={findProduct(item.productId._id).img}
                          alt={findProduct(item.productId._id).name}
                        />
                      </div>
                      <div>
                        <input
                          className="font-medium text-xs bg-transparent"
                          type="text"
                          name="name"
                          id="name"
                          value={findProduct(item.productId._id).name}
                          disabled
                        />
                        <p className="text-xs">
                          {findProduct(item.productId._id).brand}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center border rounded-lg">
                      <span className="w-7 text-center text-xs">
                        {item.quantity}
                      </span>
                    </div>
                  </div>
                ))}
                <br />
                <div className="flex border p-2 sm:flex-row flex-col justify-between font-semibold bg-stone-50 mb-2 rounded">
                  <p className="text-xs medium">Total</p>
                  <p className="text-sm font-semibold">
                    ₦{filteredCart[0].totalPrice}
                  </p>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label htmlFor="otp-code" className="font-medium text-xs">
                      Input OTP code
                    </label>
                    <span className="text-blue-300 text-xs cursor-pointer">
                      Resend OTP
                    </span>
                  </div>
                  <span
                    className={`text-xs text-red-500 ${
                      inputFilled ? "block" : "hidden"
                    }`}
                  >
                    **Please enter OTP code.
                  </span>
                  <input
                    type="text"
                    className="block border w-full mt-1 p-2 text-xs bg-stone-50"
                    name="OTP code"
                    id="otp-code"
                    placeholder="Input OTP code"
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <button
                  className="bg-violet-950 rounded text-sm text-white py-2 px-7 my-3 w-full"
                  type="button"
                  onClick={handlePurchase}
                >
                  {isLoading ? <Preloader /> : "Purchase"}
                  Purchase
                </button>
              </section>
            </section>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};
