import { useEffect, useState } from "react";
import { SalesHistoryProps } from "../types/types";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import axios from "axios";
import Button from "./elements/Button";
import { IoAddSharp } from "react-icons/io5";

const SalesHistory = ({
  openModal,
  openSuccessModal,
  openProductModal,
  setProductsObj,
  setCustomerId,
  openCalendarModal
}: SalesHistoryProps) => {
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const { token, uid } = useAppSelector((store: RootState) => store.auth);

  useEffect(() => {
    getSalesHistory();
  }, []);

  const getSalesHistory = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getCustomersByMarketerId`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setHistories(response.data);
     console.log(response.data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      setHistories([]);
    }
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div className={`w-full overflow-x-auto ${isLoading && 'animate-pulse h-[50vh]'}`}>
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">Sales History</h1>
            <div className="flex items-center">
              <Button
                onClick={openModal}
                className="mr-3 flex items-center border text-sm border-black text-black py-2 px-6 cursor-pointer hover:text-gray-700 hover:border-gray-700"
              >
                <IoAddSharp className="mr-2" /> Add new customer
              </Button>{" "}
            </div>
          </div>
          <div className="w-full" style={{ minWidth: "700px" }}>
            <div className="bg-gray-50 flex font-bold p-4">
              <div className="flex-1 p-2 ">S/N</div>
              <div className="flex-1 p-2 ">Name</div>
              <div className="flex-1  p-2">Items</div>
              <div className="flex-1 p-2 ">Total Paid</div>
              <div className="flex-1 p-2">Total Left</div>
              <div className="flex-1 p-2">Calendar</div>
            </div>
            <div className="px-4">
              {histories.length === 0 && isLoading === false && (
                <div className="flex items-center justify-center flex-col py-6">
                  <img
                    src="assets/nothing.png"
                    alt=""
                    className="w-[230px] h-[230px]"
                  />
                  <p className="italic">No sales history...</p>
                </div>
              )}
              {histories.map((history: any, index) => {
                const totalRemaining = history.events.reduce(
                  (acc: number, event: any) => acc + event.totalAmount,
                  0
                );
                const totalAmount = history.orders.reduce(
                  (acc: number, order: any) => acc + order.totalPrice,
                  0
                );
                const totalPaid = totalAmount - totalRemaining;
                return (
                  <div
                    className="flex border-b border-gray-300 py-2"
                    key={index}
                  >
                    <div className="flex-1 text-secondary p-2">{index + 1}</div>
                    <div className="flex-1 p-2">{history.fullName}</div>
                    <div
                      className="flex-1  p-2 cursor-pointer underline"
                      onClick={() => {
                        openProductModal();
                        setProductsObj(history);
                      }}
                    >
                      View items
                    </div>
                    <div className="flex-1 p-2">
                      {totalPaid.toLocaleString("en-US")}
                    </div>
                    <div className="flex-1 p-2">
                      {totalRemaining.toLocaleString("en-US")}
                    </div>
                    <div className="flex-1 p-2 text-sm" onClick={() => {
                      openCalendarModal()
                      setCustomerId(history.events)
                    }}>
                     View Calendar
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SalesHistory;
