import React from 'react'
import { useNavigate } from "react-router-dom";

const InformationCard = () => {
  const navigate = useNavigate();
  return (
    <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="flex items-center justify-center lg:my-16">
    <div className="w-full lg:w-[85%] bg-gradient-to-tl hover:bg-gradient-to-tr from-[#3B2368] to-purple-700 p-14 lg:rounded-md shadow-md">
      <div className="flex flex-col items-center justify-center">
        {/* <h2 className="text-sm bg-[#623DA7] rounded-3xl w-fit px-4 py-2 mb-4 lg:mb-6 text-white">
          Vehicula diam augue
        </h2>
        <h1 className="text-[24px] lg:text-[40px] leading-7 font-bold text-center lg:leading-9 pb-6 lg:pb-8 text-white">
          Vehicula diam augue et vitae
        </h1> */}
        <p className="text-sm lg:text-base leading-5 pb-6 lg:pb-8 text-white text-center w-full lg:w-[75%]">
        Ready to upgrade your tech? Start your journey with Waygibb today—choose your gadget, select a flexible payment plan, and get your device instantly
        </p>
        <div className="flex justify-center space-x-4">
          <button onClick={() => navigate("/about")} className="px-4 py-2 bg-white text-purple-700 font-bold rounded shadow-sm hover:bg-opacity-75">
            About Us
          </button>
          <button onClick={() => navigate("/contact")} className="px-4 py-2 bg-purple-500 text-white font-bold rounded shadow-sm hover:bg-opacity-75">
            Contact us
          </button>
        </div>
      </div>
    </div>
  </div>  )
}

export default InformationCard