import Button from "./elements/Button";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { FaArrowUpLong } from "react-icons/fa6";
import { Navbar2Props } from "../types/types";
import {IoIosAdd} from "react-icons/io";

const Navbar2 = ({tab, openModal}: Navbar2Props) => {
  const { role } = useAppSelector((store: RootState) => store.auth);

  return (
    <main className="flex items-center flex-col lg:flex-row justify-between mt-[5rem] w-full border-t-2 py-6 px-4 lg:px-8">
      <div className="w-full lg:w-fit">
        <h1 className="text-lg font-bold">{tab}</h1>
        <p>
         WayGibb{" "}
        </p>
      </div>
      <div className="w-full lg:w-fit mt-4 lg:mt-0">
        {role === "admin" ? (
          <Button
            onClick={openModal}
            className="bg-secondary text-white flex items-center p-6 cursor-pointer hover:bg-purple-900"
          >
            <IoIosAdd className="mr-2 w-6 h-6" /> Add product
          </Button>
        ) : (
          <Button
            onClick={openModal}
            className="bg-secondary text-white flex items-center p-6 cursor-pointer hover:bg-purple-900"
          >
            <FaArrowUpLong className="mr-2" /> Send dispute message
          </Button>
        )}
      </div>
    </main>
  );
};

export default Navbar2;
