import { useState, useEffect } from "react";
import axios from "axios";
import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import { MarketersModal } from "./MarketersModal";

export const Marketers = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [marketers, setMarketers] = useState([]);
  const [selectedMarketer, setSelectedMarketer] = useState<any>(null); 

  const handleClick = (marketer: any) => {
    setSelectedMarketer(marketer); 
    setShowModal((prev) => !prev);
  };
  
  const { token } = useAppSelector((store: RootState) => store.auth);
  useEffect(() => {
    getMarketersHandler()
  }, [])

  const getMarketersHandler = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getMarketerAndCustomer`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setMarketers(response.data);
      setIsLoading(false)
    } catch (error: any) {
      setMarketers([]);
      setIsLoading(false)
    }
  };

  return (
    <>
      <section className="bg-[#F6F4F8] px-4 lg:px-6 2xl:px-8 pt-2 pb-6">
        <div className={`bg-white p-5 lg:overflow-x-hidden overflow-x-scroll ${isLoading && "animate-pulse h-[50vh]"}`}>
          <table className="md:w-full w-[46rem] text-sm text-left rtl:text-right">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-2 py-3 font-medium">S/N</th>
                <th className="px-6 py-3 font-medium">Name</th>
                <th className="px-6 py-3 font-medium">No of customers</th>
                <th className="px-6 py-3 font-medium">Action</th>
              </tr>
            </thead>
            <tbody>
              {marketers.map((val: any, index) => {
                return (
                  <>
                    <tr key={index} className="cursor-pointer" onClick={() => handleClick(val)}>
                      <td className="px-2 py-3 text-secondary">{index + 1}</td>
                      <td className="px-6 py-3">{val.fullName}</td>
                      <td className="px-6 py-3">{val.customerCount}</td>
                      <td className="px-6 py-3">View Marketer</td>

                    </tr>
                    {showModal && selectedMarketer && ( 
        <MarketersModal
          name={selectedMarketer.fullName}
          address={selectedMarketer.address}
          email={selectedMarketer.email}
          phoneNo={selectedMarketer.phoneNumber}
          staffID={selectedMarketer.role}
          setShowModal={setShowModal}
          image={selectedMarketer.image}
        />
      )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
