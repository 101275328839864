import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllHelpCenter } from "../thunk";

interface helpCenterState {
  helpCenter: helpCenter[];
  status: string;
  error: string;
}

interface helpCenter {
  _id: string;
  marketerId: string;
  customerAccountNo: string;
  message: string;
  subject: string;
  createdAt: string;
}

const initialState: helpCenterState = {
  helpCenter: [],
  status: "idle",
  error: "",
};

const helpCenterSlice = createSlice({
  name: "helpCenter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllHelpCenter.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(
        getAllHelpCenter.fulfilled,
        (state, action: PayloadAction<helpCenter[]>) => {
          state.status = "success";
          state.helpCenter = action.payload;
          state.error = "nil";
        }
      )
      .addCase(getAllHelpCenter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default helpCenterSlice.reducer;
