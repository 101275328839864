import React, { useState, useEffect } from "react";
import { TfiClose } from "react-icons/tfi";
import { AiOutlineAlignRight } from "react-icons/ai";
import Nav from "./Nav";

const Header = () => {
  const [nav, setNav] = useState(false);
  const [isFixed, setIsFixed] = useState(false); 

  useEffect(() => {
   
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsFixed(scrollY > 100); 
  };

  return (
    <>
      <nav
        id="header"
        className={`${
          isFixed
            ? "fixed top-0 left-0 right-0 shadow-lg z-[9999]" 
            : "relative"
        } pt-[35px]  w-full  transition-all duration-500`}
      >
        <section className="px-4 py-4 bg-white shadow-lg rounded-sm lg:py-0 lg:px-6">
          <div className="items-center justify-between hidden py-4 lg:flex">
            <a href="/" className="text-2xl">
              <img src="assets/waygibbLogoRbg.png" className="h-[9vh]" />
            </a>

            <ul>
              <Nav />
            </ul>
          </div>

          {/* Mobile */}
          <div className="flex items-center justify-between lg:hidden">
            <a href="/" className="text-xl font-bold">
              <img src="assets/waygibbLogoRbg.png" className="h-[5vh]" />
            </a>
            <ul
              onClick={() => setNav(!nav)}
              className={`absolute bg-white h-[80vh] top-[63px] z-20 items-center w-full left-0 py-10 ${
                nav ? "block" : "hidden"
              }`}
            >
              <Nav />
            </ul>
            <div className="block text-3xl lg:hidden" onClick={() => setNav(!nav)}>
              {!nav ? <AiOutlineAlignRight /> : <TfiClose />}
            </div>
          </div>
        </section>
      </nav>
    </>
  );
};

export default Header;
