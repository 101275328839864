import { useEffect, useState } from "react";
import { MigrationModalProps } from "../../../types/types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  getAllCustomers,
  getMarketersWithCustomers,
  migrateCustomers,
} from "../../../redux/thunk";
import { nanoid } from "@reduxjs/toolkit";
import { Preloader } from "../../../components/elements/Preloader";
import toast from "react-hot-toast";

export const MigrationModal = ({
  selectedMarketer,
  setShowMigrationModal,
  setShowSuccess,
}: MigrationModalProps) => {
  const [customersID, setCustomersID] = useState<string[]>([]);
  const [marketerId, setMarketerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { customers } = useAppSelector((store: RootState) => store.customers);
  const { marketers } = useAppSelector((store: RootState) => store.marketers);

  useEffect(() => {
    
      dispatch(getAllCustomers());
  }, [dispatch]);

  const handleSelect = (id: string) => {
    setCustomersID((prevIDs) => {
      if (prevIDs.includes(id)) {
        return prevIDs.filter((item) => item !== id);
      } else {
        return [...prevIDs, id];
      }
    });
  };

  const filteredCustomers = customers.filter(
    (customer) => customer.marketerId._id === selectedMarketer
  );

  const handleSelectAll = () => {
    const allIDs = customers.map((customer) => customer._id);
    setCustomersID(allIDs);
  };

  const handleCancel = () => {
    setShowMigrationModal(false);
  };

  const handleMarketerSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMarketerId(event.target.value);
  };

  const handleAssign = () => {
    setIsLoading(true);
    const data = {
      customerIds: customersID,
      marketerId: marketerId,
    };
    dispatch(migrateCustomers(data))
      .then(() => {
        setShowSuccess(true);
        handleCancel();
        setIsLoading(false);
        dispatch(getMarketersWithCustomers());
      })
      .catch((error) => {
        console.error("An error occurred while migrating customers:", error);
        toast.error("Migration failed!")
      });
  };

  return (
    <>
      <div className="justify-center sm:items-center items-end flex overflow-x-hidden overflow-y-auto fixed h-full inset-0 z-50 outline-none focus:outline-none bottom-0">
        <div className="relative h-5/6 sm:w-auto sm:my-6 sm:mx-auto sm:max-w-lg w-72">
          <div className="border-0 rounded-t-sm sm:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="bg-white shadow-md p-4 sm:rounded-lg rounded-t-lg sm:static fixed bottom-0 left-5 right-5 sm:w-96">
              <div className="flex items-center justify-between pb-4">
                <h1 className="text-base font-semibold ">Total customers</h1>
                <div
                  className="flex items-center border text-sm border-secondary200 py-2 px-5 rounded-md shadow-sm"
                  onClick={handleSelectAll}
                >
                  <p>Select all</p>
                </div>
              </div>
              {filteredCustomers.map((item) =>
                item.lenght === 0 ? (
                  <p>No Marketer</p>
                ) : (
                  <section key={nanoid()} className="flex items-center">
                    <span>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="mr-2"
                        onChange={() => handleSelect(item._id)}
                        checked={customersID.includes(item._id)}
                      />
                    </span>
                    <div className="flex items-center w-full justify-between py-2">
                      <div>
                        <h1 className="font-semibold text-sm">
                          {item.fullName}
                        </h1>
                        <p className="text-gray-700 text-sm">
                          {item.customerAccountNo}
                        </p>
                      </div>
                      <div
                        className="flex items-center cursor-pointer bg-secondary100 text-secondary border-secondary200 outline-none py-2 px-4 border text-sm rounded-md shadow-sm"
                        onClick={() => handleSelect(item._id)}
                      >
                        <p className="pl-2">Migrate</p>
                      </div>{" "}
                    </div>
                  </section>
                )
              )}
              {customersID.length === 0 ? null : (
                <div>
                  <select
                    name="marketersList"
                    id="marketersList"
                    onChange={handleMarketerSelect}
                    value={marketerId}
                  >
                    <option value="">Select a marketer</option>
                    {marketers
                      .filter((marketer) => marketer._id !== selectedMarketer) 
                      .map((marketer) => (
                        <option key={nanoid()} value={marketer._id}>
                          {marketer.fullName}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="flex justify-between gap-4 mt-2">
                <button
                  type="button"
                  className="p-2 border w-1/2 rounded-md bg-gray-100 border-gray-300 text-gray-500 text-sm"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="p-2 border w-1/2 bg-secondary text-white rounded-md text-sm"
                  onClick={handleAssign}
                >
                  {isLoading ? <Preloader /> : "Assign"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
