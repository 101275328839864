import { useEffect, useState } from "react";
import { MigrationModal } from "./MigrationModal";
import { Success } from "../../../components/Store/Success";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getMarketersWithCustomers } from "../../../redux/thunk";
import { RootState } from "../../../redux/store";
import { nanoid } from "@reduxjs/toolkit";

export const Migration = () => {
  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedMarketer, setSelectedMarketer] = useState<string>("")

  const dispatch = useAppDispatch();
  const { marketers } = useAppSelector((store: RootState) => store.marketers);

  useEffect(() => {
    if (marketers.length === 0) {
      dispatch(getMarketersWithCustomers());
    }
  }, [dispatch, marketers.length]);

  const handleClick = (id: string) => {
    setSelectedMarketer(id);
    setShowMigrationModal((prev) => !prev);
  };  

  return (
    <>
      <section className="bg-[#F6F4F8] px-4 lg:px-6 2xl:px-8 pt-2 pb-6">
        <div className="bg-white p-5 lg:overflow-x-hidden overflow-x-scroll">
          <table className="md:w-full w-[46rem] text-sm text-left rtl:text-right">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-2 py-3 font-medium">S/N</th>
                <th className="px-6 py-3 font-medium">Name</th>
                <th className="px-6 py-3 font-medium">View Items</th>
              </tr>
            </thead>
            <tbody>
              {marketers.map((item, key) => {
                return (
                  <tr
                    key={nanoid()}
                    className="cursor-pointer"
                    onClick={() => handleClick(item._id)}
                  >
                    <td className="px-2 py-3">{key + 1}</td>
                    <td className="px-6 py-3">{item.fullName}</td>
                    <td className="px-6 py-3">{item.customerCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {showMigrationModal ? (
          <MigrationModal
            selectedMarketer={selectedMarketer}
            setShowMigrationModal={setShowMigrationModal}
            setShowSuccess={setShowSuccess}
          />
        ) : null}
        {showSuccess ? (
          <Success
            heading="Migration Successful"
            details="Customers Migration now successful"
            setShowSuccess={setShowSuccess}
          />
        ) : null}
      </section>
    </>
  );
};
