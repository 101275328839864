import { ItemCard } from "../../components/Store/ItemCard";
import { useEffect } from "react";
import { fetchProducts } from "../../redux/thunk";
import { products } from "../../types/types";
import { RootState } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export const Store = () => {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector((store: RootState) => store.products);

  useEffect(() => {
      dispatch(fetchProducts());
  }, [dispatch]);

  const sortedProducts = [...products].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
  return (
    <>
      <section className="bg-[#F6F4F8] px-4 lg:px-6 2xl:px-8 pt-2">
        <section className="place-items-center w-full grid grid-cols-1 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 rounded">
        {sortedProducts.map((item: products) => (
          <ItemCard
            key={item._id}
            productId={item._id}
            img={item.imageUrl}
            name={item.name}
            description={item.description}
            outright={item.outrightPrice}
            flexible={item.flexiblePrice}
          />
        ))}
      </section>
      </section>
    </>
  );
};
