import React from "react";

const Testimonial = () => {
  return (
    <div className=" flex items-center justify-center bg-[#1E1234] py-12 lg:py-16  text-white">
      <div className="w-[85%]">
        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          <div className="w-full lg:w-[48%] mb-8 lg:mb-0">
            <h1 className="text-base leading-5 font-bold mb-3 lg:mb-4">Testimonials</h1>
            <h1 className="text-[24px] lg:text-[40px] leading-7 lg:leading-8 mb-6 lg:mb-8">
              WayGibb Gives the best
            </h1>
            <p className="text-base leading-5">
             waygibb
            </p>
          </div>
          <div data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1500" className="bg-white rounded-md shadow-md p-8 lg:p-14 w-full lg:w-[48%]">
            <p className="text-base leading-6 text-black mb-6">
             They Promised a flexible plan and they delivered the best product
            </p>
            <div className="flex items-center">
              <img
                src="/assets/picture.jpg"
                alt="author"
                className="rounded[50%] h-12 w-12 mr-4"
              />
              <div>
                <h1 className="text-[20px] font-semibold leading-6 text-black">
                  Adams wells{" "}
                </h1>
                <p className="text-base left-5 text-gray-400">
                  Investor officer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
